import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    CircularProgress,
    Alert,
    Modal
} from '@mui/material';
import {
    Send as SendIcon,
    AttachFile as AttachFileIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Notifications as NotificationsIcon
} from '@mui/icons-material';
import { APIData, org } from 'authentication/APIData';
import Avatar from '@mui/material/Avatar'; 
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import PropTypes from 'prop-types';
import { Badge, CircleIcon, MailIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import SoftInput from 'components/SoftInput';
const MentorChat = () => {
    const [messages, setMessages] = useState([]);

    const [selectedChat, setSelectedChat] = useState(null);
    const [reply, setReply] = useState('');
    const [loading, setLoading] = useState(false);
    const [mentorId, setMentorId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = React.useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const email = sessionDetails?.email;
    const navigate = useNavigate();
    const updateViewStatus = async (messageId) => {
        try {
            const url = `${APIData.api}student/updates/update-view-status/${messageId}?viewStatus=READ`;
            await fetch(url, {
                method: 'PUT',
                headers: APIData.headers
            });
            setMessages(prevMessages =>
                prevMessages.map(msg =>
                    msg.id === messageId ? { ...msg, viewStatus: 'READ' } : msg
                )
            );
        } catch (error) {
            console.error('Error updating view status:', error);
            setError('Failed to update message status');
        }
    };
    const handleMessageSelect = async (message) => {
        setSelectedChat(message);
        if (message.viewStatus === 'UNREAD') {
            await updateViewStatus(message.id);
        }
    };
    const formatTime = (dateArray) => {
        try {
            const date = new Date(
                dateArray[0],
                dateArray[1] - 1,
                dateArray[2],
                dateArray[3],
                dateArray[4],
                dateArray[5]
            );
            return date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            });
        } catch (error) {
            console.error('Error formatting time:', error);
            return 'Invalid time';
        }
    };
    const isToday = (dateTimeArray) => {
        if (!Array.isArray(dateTimeArray)) return false;
        const [year, month, day] = dateTimeArray;
        const messageDate = new Date(year, month - 1, day);
        const today = new Date();
        return messageDate.toDateString() === today.toDateString();
    };
    useEffect(() => {
        const fetchMentorId = async () => {
            try {
                const response = await fetch(`${APIData.api}studentadmissiondetails/?org=${org}`, {
                    headers: APIData.headers
                });
                const data = await response.json();
                if (data && data.mentor) {
                    setMentorId(data.mentor);
                    fetchMessages(data.mentor);
                }
            } catch (error) {
                console.error('Error fetching mentor ID:', error);
                setError('Failed to fetch mentor ID');
            }
        };
        const fetchMessages = async () => {
            setLoading(true);
            try {
                const currentDate = new Date();
                const url = `${APIData.api}student/updates/mentor?mentorId=${email}&org=${org}&year=${currentDate.getFullYear()}&month=${currentDate.getMonth() + 1}`;
                const response = await fetch(url, { headers: APIData.headers });
                const data = await response.json();
                const todayMessages = Array.isArray(data) ?
                    data.filter(msg =>
                        isToday(msg.createdDateTime) &&
                        msg.createdBy !== email
                    ) : [];
                setMessages(todayMessages);
            } catch (error) {
                console.error('Error fetching messages:', error);
                setError('Failed to fetch messages');
            }
            setLoading(false);
        };
        fetchMessages();
        fetchMentorId();
    }, []);
    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        console.log('Selected files:', files); // Debug log
        // Validate file types
        const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
        const invalidFiles = files.filter(file => !validTypes.includes(file.type));
        if (invalidFiles.length > 0) {
            setError('Please select only image files (JPEG, PNG, GIF)');
            return;
        }
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);
        console.log('Total size:', totalSize); // Debug log
        if (files.length > 4) {
            setError('Maximum 4 images allowed');
            return;
        }
        if (totalSize > 5 * 1024 * 1024) {
            setError('Total file size should not exceed 5MB');
            return;
        }
        setSelectedFiles(files);
        console.log('Files set to state:', files); // Debug log
    };
    const uploadImages = async (messageId) => {
        try {
            const uploadedImages = [];
            for (const file of selectedFiles) {
                const formData = new FormData();
                formData.append('mentorFile', file);
                const headers = { ...APIData.headers };
                delete headers['Content-Type'];
                const response = await fetch(`${APIData.api}student/updates/${messageId}/upload-images`, {
                    method: 'POST',
                    headers: headers,
                    body: formData
                });
                if (!response.ok) {
                    throw new Error(`Upload failed with status: ${response.status}`);
                }
                const data = await response.json();
                uploadedImages.push(data.imageUrl); // Assuming the API returns the image URL
            }
            // Update the selected chat with new images immediately
            setSelectedChat(prevChat => ({
                ...prevChat,
                mentorImages: [...(prevChat.mentorImages || []), ...uploadedImages]
            }));
            setSelectedFiles([]);
            return uploadedImages;
        } catch (error) {
            console.error('Error uploading image:', error);
            setError('Failed to upload image. Please try again.');
            throw error;
        }
    };
    const handleSendReply = async () => {
        if (reply.trim().length < 5) {  // Corrected length condition (should be 15, not 5)
            setErrorMessage('Message should be more than 5 characters');
            return; // Prevent sending if the message is too short
        }

        setErrorMessage(''); // Clear error message if the message length is valid

        if (!selectedFiles.length && !reply.trim()) return;  // Don't send if there's no message and no files

        try {
            setLoading(true);
            let payload = { ...selectedChat };

            // Add a new query if there's a text reply
            if (reply.trim()) {
                const newQuery = {
                    comments: reply,
                    updatedBy: email,
                    updatedDateTime: [
                        new Date().getFullYear(),
                        new Date().getMonth() + 1,
                        new Date().getDate(),
                        new Date().getHours(),
                        new Date().getMinutes(),
                        new Date().getSeconds()
                    ]
                };
                payload.queries = [...selectedChat.queries, newQuery];
            }

            // Send the message update first if there's a text reply
            if (reply.trim()) {
                const response = await fetch(`${APIData.api}student/updates`, {
                    method: 'POST',
                    headers: {
                        ...APIData.headers,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    throw new Error(`Reply failed with status: ${response.status}`);
                }

                payload = await response.json();
            }

            // Handle image upload if there are any files selected
            if (selectedFiles.length > 0) {
                const uploadedImages = await uploadImages(payload.id || selectedChat.id);
                payload = {
                    ...payload,
                    mentorImages: [...(payload.mentorImages || []), ...uploadedImages]
                };
            }

            // Update local state with the new payload
            setSelectedChat(payload);
            setMessages(prevMessages =>
                prevMessages.map(msg =>
                    msg.id === selectedChat.id ? payload : msg
                )
            );

            setReply(''); // Reset the reply field after sending

        } catch (error) {
            console.error('Error in send reply:', error);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const ImageGallery = ({ images }) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`Uploaded ${index + 1}`}
                    style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'cover',
                        borderRadius: '4px'
                    }}
                />
            ))}
        </Box>
    );
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };
    const handleCloseModal = () => {
        setSelectedImage(null);
    };
    const ImageStrip = ({ images }) => (
        <Box sx={{ display: 'flex', gap: 1, overflowX: 'auto', py: 1 }}>
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    onClick={() => handleImageClick(image)}
                    style={{
                        height: '60px',
                        width: '60px',
                        objectFit: 'cover',
                        cursor: 'pointer',
                        borderRadius: '4px'
                    }}
                />
            ))}
        </Box>
    );
    ImageGallery.propTypes = {
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
    };
    ImageStrip.propTypes = {
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
    };
    const navigateToMassNotification = () => {
        navigate('/massNotification');
    };
    return (
        <DashboardLayout>
            <Box sx={{ display: 'flex', height: '81vh', bgcolor: '#f5f5f5',overflow:"hidden" }}>
                {/* Sidebar */}
                <Paper sx={{ width: '350px', overflow: 'hidden', borderRadius: 0, height: '100%' }}>
                    <Box sx={{ p: 2 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            startIcon={<NotificationsIcon />}
                            sx={{ mb: 2 }}
                            onClick={navigateToMassNotification}
                        >
                            Send Mass Notification
                        </Button>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <List>
                                {messages.map((message) => (
                                    <ListItem
                                        key={message.id}
                                        button
                                        selected={selectedChat?.id === message.id}
                                        onClick={() => handleMessageSelect(message)}
                                    >
                                        <ListItemText
                                            primary={
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Typography variant="subtitle1">
                                                        {message.studentName.charAt(0).toUpperCase() +
                                                            message.studentName.slice(1).toLowerCase()}
                                                    </Typography>
                                                </Box>
                                            }
                                            secondary={
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    sx={{
                                                        fontWeight: message.viewStatus === 'UNREAD' ? 600 : 400,
                                                    }}
                                                >
                                                    {message.grade}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                </Paper>
    
                {/* Chat Section */}
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '900px',
                        margin: '10px',
                        height: '100%',
                        overflow: 'hidden',
                    }}
                >
                    {selectedChat ? (
                        <>
                            {/* Chat Header */}
                            <Paper sx={{ p: 2, borderRadius: 0 }}>
                                <Box sx={{ p: 1, backgroundColor: 'rgb(53, 182, 237)', borderRadius: 1 }}>
                                    <Typography variant="h6" sx={{ color: 'white' }}>
                                        {selectedChat.studentName.charAt(0).toUpperCase() +
                                            selectedChat.studentName.slice(1).toLowerCase()}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {selectedChat.grade}
                                    </Typography>
                                </Box>
                            </Paper>
    
                            {/* Chat Bubbles */}
                            <Box sx={{ flex: 1, p: 2 }}>
                                {selectedChat.queries.map((query, index) => {
                                    const isMentorMessage =
                                        query.updatedBy === APIData.sessiondetails.email;
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: isMentorMessage
                                                    ? 'row-reverse'
                                                    : 'row',
                                                alignItems: 'flex-end',
                                                mb: 2,
                                            }}
                                        >
                                            {/* Optional Avatar */}
                                            <Avatar
                                                sx={{
                                                    width: 32,
                                                    height: 32,
                                                    bgcolor: isMentorMessage ? 'rgb(139, 209, 228)' : 'gray',
                                                    mr: isMentorMessage ? 0 : 2,
                                                    ml: isMentorMessage ? 2 : 0,
                                                }}
                                            >
                                                {isMentorMessage ? 'M' : 'S'}
                                            </Avatar>
                                            {/* Chat Bubble */}
                                            <Paper
                                                elevation={2}
                                                sx={{
                                                    p: 2,
                                                    maxWidth: '70%',
                                                    borderRadius: isMentorMessage
                                                        ? '16px 16px 0px 16px'
                                                        : '16px 16px 16px 0px',
                                                    bgcolor: isMentorMessage
                                                        ? 'rgb(53, 182, 237)'
                                                        : 'rgb(245, 245, 245)',
                                                    color: isMentorMessage ? 'white' : 'black',
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                <Typography>{query.comments}</Typography>
                                                <Typography
                                                    variant="caption"
                                                    color={
                                                        isMentorMessage
                                                            ? 'rgba(255, 255, 255, 0.7)'
                                                            : 'textSecondary'
                                                    }
                                                    sx={{ display: 'block', mt: 1 }}
                                                >
                                                    {formatTime(query.updatedDateTime)}
                                                </Typography>
                                            </Paper>
                                        </Box>
                                    );
                                })}
    
                                {/* Image Strips */}
                                {selectedChat.studentImages && selectedChat.studentImages.length > 0 && (
                                    <Box sx={{ maxWidth: '100%', mb: 2 }}>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            sx={{ display: 'block', mb: 1 }}
                                        >
                                            Student Images
                                        </Typography>
                                        <ImageStrip images={selectedChat.studentImages} />
                                    </Box>
                                )}
                                {selectedChat.mentorImages && selectedChat.mentorImages.length > 0 && (
                                    <Box sx={{ maxWidth: '100%', mb: 2 }}>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            sx={{ display: 'block', mb: 1 }}
                                        >
                                            Mentor Images
                                        </Typography>
                                        <ImageStrip images={selectedChat.mentorImages} />
                                    </Box>
                                )}
                            </Box>
    
                            {/* Chat Input */}
                            <Paper sx={{ p: 2, borderRadius: 0 ,flexShrink:0}}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <input
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileSelect}
                                        />
                                        <IconButton
                                            onClick={() => fileInputRef.current.click()}
                                            disabled={loading}
                                            sx={{ color: 'rgb(53, 182, 237)' }}
                                        >
                                            <AttachFileIcon />
                                        </IconButton>
                                        <SoftInput
                                            multiline
                                            rows={2}
                                            value={reply}
                                            onChange={(e) => {
                                                const message = e.target.value;
                                                setReply(message);
                                                if (message.trim().length >= 15) setErrorMessage('');
                                            }}
                                            placeholder="Type your reply..."
                                            variant="outlined"
                                            disabled={loading}
                                            sx={{
                                                width: '100%',
                                                '& .MuiInputBase-root': {
                                                    height: 'auto',
                                                    padding: '8px 12px',
                                                },
                                            }}
                                        />
                                        {errorMessage && (
                                            <Typography color="error" variant="body2">
                                                {errorMessage}
                                            </Typography>
                                        )}
                                        <IconButton
                                            color="primary"
                                            onClick={handleSendReply}
                                            disabled={loading || reply.trim().length < 5}
                                            sx={{ color: 'rgb(53, 182, 237)' }}
                                        >
                                            {loading ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <SendIcon />
                                            )}
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Paper>
                        </>
                    ) : (
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography color="textSecondary">
                                Select a message to view the conversation
                            </Typography>
                        </Box>
                    )}
                </Box>
                {error && (
                    <Alert
                        severity="error"
                        sx={{ position: 'fixed', bottom: 16, right: 16 }}
                        onClose={() => setError('')}
                    >
                        {error}
                    </Alert>
                )}
            </Box>
        </DashboardLayout>
    );
    
};
export default MentorChat;