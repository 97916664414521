import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from "sweetalert2";
import TabNavigation from "./tabNvigation";
import Footer from "examples/Footer";

function AllAllocatedAsset() {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const navigate = useNavigate();

  const fetchAssets = async () => {
    if (!selectedEmployeeId) {
      setAssets([]);
      return;
    }

    setLoading(true);
    try {
      const url = `${APIData.api}employee-assets/id?empid=${selectedEmployeeId}`;
      const response = await axios.get(url, { headers: APIData.headers });
      setAssets(response.data);
    } catch (error) {
      Swal.fire({
        title: "Success!",
        text: "No assets allocated to this employee.",
        icon: "info",
        confirmButtonText: "OK",
      });
      console.error("Failed to fetch assets.", error);
      setAssets([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployeeDetails = async () => {
    try {
      const url = `${APIData.api}employee/details?org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      
      // Transform the response to extract only employee_id and name
      const employeeOptions = response.data.map(emp => ({
        value: emp.employee_id,
        label: emp.name
      }));
      
      setEmployees(employeeOptions);
    } catch (error) {
      console.error("Failed to fetch employee details.", error);
    }
  };

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  useEffect(() => {
    fetchAssets();
  }, [selectedEmployeeId]);

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployeeId(selectedOption ? selectedOption.value : "");
  };

  const handleBack = () => {
    navigate("/assets-list");
  };

  return (
    <DashboardLayout>
      <TabNavigation/>
      <SoftBox mb={3} mt={3}>
        <SoftBox px={2} mb={2}>
          <SoftTypography variant="h5" fontWeight="medium" sx={{ ml: 2 }}>
            Employee Allocated Assets
          </SoftTypography>
        </SoftBox>

        <SoftBox mb={2} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <SoftTypography variant="h6">Select Employee</SoftTypography>
          <CustomSelect
            options={employees}
            placeholder="Select Employee"
            onChange={handleEmployeeChange}
            value={
              employees.find((emp) => emp.value === selectedEmployeeId)
                ? { value: selectedEmployeeId, label: employees.find((emp) => emp.value === selectedEmployeeId).label }
                : null
            }
            isClearable={true}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "auto",
                minWidth: "200px",
                maxWidth: "400px",
                marginBottom: "20px",
              }),
              control: (provided) => ({
                ...provided,
                fontSize: "0.875rem",
              }),
              option: (provided) => ({
                ...provided,
                fontSize: "0.875rem",
              }),
            }}
          />
        </SoftBox>

        <SoftBox p={2}>
          {!selectedEmployeeId ? (
            <SoftBox sx={{ textAlign: "center", mt: 4 }}>
              <SoftTypography variant="h6" color="textSecondary">
                Please select an employee to view their allocated assets.
              </SoftTypography>
            </SoftBox>
          ) : loading ? (
            <SoftBox sx={{ textAlign: "center", mt: 4 }}>
              <SoftTypography variant="h6" color="textSecondary">
                Loading assets...
              </SoftTypography>
            </SoftBox>
          ) : (
            <>
              <SoftBox sx={{ mb: 2, textAlign: "center" }}>
                <SoftTypography variant="h6">
                  Showing details for Employee: {employees.find(emp => emp.value === selectedEmployeeId)?.label}
                </SoftTypography>
              </SoftBox>
              {assets.length === 0 ? (
                <SoftBox sx={{ textAlign: "center", mt: 4 }}>
                  <SoftTypography variant="h6" color="textSecondary">
                    No assets allocated to this employee.
                  </SoftTypography>
                </SoftBox>
              ) : (
                <Grid container spacing={3}>
                  {assets.map((asset) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={asset.asset_id}>
                      <Card
                        variant="outlined"
                        sx={{
                          borderRadius: "12px",
                          boxShadow: 3,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100%",
                        }}
                      >
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <SoftTypography
                            variant="body1"
                            fontWeight="bold"
                            sx={{
                              textAlign: "center",
                              mb: 2,
                              mt: 2,
                              backgroundColor: "silver",
                              borderRadius: "4px",
                              padding: "4px",
                            }}
                          >
                            {selectedEmployeeId}
                          </SoftTypography>
                          <SoftTypography variant="body2" color="textSecondary">
                            <b>Asset Name:</b> {asset.asset_name}
                          </SoftTypography>
                          <SoftTypography variant="body2" color="textSecondary">
                            <b>Allocation Status:</b> {asset.allocation}
                          </SoftTypography>
                          <SoftTypography variant="body2" color="textSecondary">
                            <b>Description:</b> {asset.description}
                          </SoftTypography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AllAllocatedAsset;