// import React, { useEffect, useState } from "react";
// import {
//   Card,
//   CardContent,
//   Typography,
//   Box,
//   CircularProgress,
//   Paper
// } from "@mui/material";
// import { Mail, Person, AccessTime } from "@mui/icons-material";
// import PropTypes from 'prop-types';
// import { AlertCircle } from "lucide-react";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { APIData } from "authentication/APIData";
// import { exitKeyWord } from "authentication/APIData";
// import { org } from "authentication/APIData";
// import PageLayout from "examples/LayoutContainers/PageLayout";
// import SoftTypography from "components/SoftTypography";
// import SoftBox from "components/SoftBox";
// import { Link } from "react-router-dom";
// import * as AiIcons from "react-icons/ai";

// // TaskCard Component with Prop Validation
// const TaskCard = ({ task }) => {
//   const {
//     id,
//     empEmail,
//     empUserName,
//     createdTime,
//     approvedBy,
//     updatedTime
//   } = task;

//   return (
//     <Card sx={{
//       width: "280px",
//       backgroundColor: "#f8f8f8",
//       margin: "0.5rem 0",
//       padding: "0.8rem",
//       borderRadius: "7px",
//       border: "1px solid #ccc",
//       boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
//       '&:hover': {
//         backgroundColor: "#f4f4f4"
//       }
//     }}>
//       <CardContent>
//         <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//           <Mail sx={{ color: "#666", mr: 1 }} />
//           <Typography variant="body2" color="#24aef7">
//             {empEmail}
//           </Typography>
//         </Box>
        
//         <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//           <Person sx={{ color: "#666", mr: 1 }} />
//           <Typography variant="body2" color="#24aef7">
//             {empUserName}
//           </Typography>
//         </Box>
        
//         <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//           <AccessTime sx={{ color: "#666", mr: 1 }} />
//           <Typography variant="body2" color="#24aef7">
//             {new Date(createdTime).toLocaleString()}
//           </Typography>
//         </Box>

//         {approvedBy && (
//           <>
//             <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//               <Person sx={{ color: "#16a34a", mr: 1 }} />
//               <Typography variant="body2" color="#16a34a">
//                 {approvedBy}
//               </Typography>
//             </Box>
//             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//               <AccessTime sx={{ color: "#16a34a", mr: 1 }} />
//               <Typography variant="body2" color="#16a34a">
//                 {new Date(updatedTime).toLocaleString()}
//               </Typography>
//             </Box>
//           </>
//         )}
//       </CardContent>
//     </Card>
//   );
// };

// TaskCard.propTypes = {
//   task: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     empEmail: PropTypes.string.isRequired,
//     empUserName: PropTypes.string.isRequired,
//     createdTime: PropTypes.string.isRequired,
//     approvedBy: PropTypes.string,
//     updatedTime: PropTypes.string,
//     description: PropTypes.string,
//     status: PropTypes.string.isRequired
//   }).isRequired
// };

// // Column Component with Prop Validation
// const Column = ({ title, tasks }) => (
//   <Paper sx={{
//     width: '100%',
//     minHeight: "100%",
//     backgroundColor: "#f4f4f4",
//     padding: "1rem",
//     margin: "0.5rem",
//     borderRadius: "5px",
//     border: "1px solid #ccc"
//   }}>
//     <Typography
//       variant="h6"
//       sx={{
//         color: "#24aef7",
//         mb: 2,
//         textAlign: "center",
//         fontWeight: "bold"
//       }}
//     >
//       {title}
//     </Typography>
//     <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
//       {tasks.map(task => (
//         <TaskCard key={task.id} task={task} />
//       ))}
//     </Box>
//   </Paper>
// );

// Column.propTypes = {
//   title: PropTypes.string.isRequired,
//   tasks: PropTypes.arrayOf(PropTypes.shape(TaskCard.propTypes.task)).isRequired
// };

// // Main Board Component
// const ControlledBoard = () => {
//   const [columns, setColumns] = useState([]);
//   const [lifeCycleStatus, setLifeCycleStatus] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchExitLifeCycleStatusesPending = async (status) => {
//     try {
//       const url = `${APIData.api}employee/exit/pending/status-type?status=${status}&type=${exitKeyWord}&org=${org}`;
//       const response = await axios.get(url, { headers: APIData.headers });
//       return response.data;
//     } catch (err) {
//       toast.error("Failed to fetch status data");
//       throw err;
//     }
//   };

//   const fetchExitLifeCycleStatuses = async () => {
//     setLoading(true);
//     try {
//       const url = `${APIData.api}employee/life-cycle/type?type=${exitKeyWord}&org=${org}`;
//       const response = await axios.get(url, { headers: APIData.headers });
//       const statuses = response.data || [];
      
//       setLifeCycleStatus(statuses);
      
//       const columnsData = await Promise.all(
//         statuses.map(async (status) => {
//           const tasks = await fetchExitLifeCycleStatusesPending(status.statusName);
//           return {
//             id: status.id,
//             title: status.statusName,
//             tasks
//           };
//         })
//       );

//       setColumns(columnsData.sort((a, b) => a.id - b.id));
//     } catch (err) {
//       setError(err.message);
//       toast.error("Failed to fetch lifecycle statuses");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchExitLifeCycleStatuses();
//   }, []);

//   if (error) {
//     return (
//       <Box sx={{ p: 2, display: 'flex', alignItems: 'center', color: 'error.main' }}>
//         <AlertCircle style={{ height: "1rem", width: "1rem", marginRight: "0.5rem" }} />
//         <Typography>Error loading board: {error}</Typography>
//       </Box>
//     );
//   }

//   if (loading) {
//     return (
//       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{
//       display: 'flex',
//       gap: 2,
//       p: 2,
//       overflowX: 'auto',
//       backgroundColor: '#f4f4f4',
//       borderRadius: '5px',
//       border: '1px solid #ccc'
//     }}>
//       {columns.map(column => (
//         <Column 
//           key={column.id}
//           title={column.title}
//           tasks={column.tasks}
//         />
//       ))}
//     </Box>
//   );
// };

// // Main Component
// const DetailsForExit = () => (
//   <PageLayout>
//     <Box sx={{ p: 3 }}>
//       <SoftBox p={3} mb={1} display="flex" alignItems="center">
//         <Link to="/dashboard" style={{ textDecoration: 'none' }}>
//           <AiIcons.AiOutlineCloseCircle />
//         </Link>
//         <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
//           Employee Exit Board
//         </SoftTypography>
//       </SoftBox>
//       <ControlledBoard />
//     </Box>
//   </PageLayout>
// );

// export default DetailsForExit;


import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Paper
} from "@mui/material";
import { Mail, Person, AccessTime } from "@mui/icons-material";
import PropTypes from 'prop-types';
import { AlertCircle } from "lucide-react";
import axios from "axios";
import { toast } from "react-toastify";
import { APIData } from "authentication/APIData";
import { exitKeyWord } from "authentication/APIData";
import { org } from "authentication/APIData";
import PageLayout from "examples/LayoutContainers/PageLayout";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";

// TaskCard Component with Prop Validation

const TaskCard = ({ task }) => {
  const {
    id,
    empEmail,
    empUserName,
    createdTime,
    approvedBy,
    updatedTime
  } = task;

  return (
    <Card sx={{
      width: "280px",
      backgroundColor: "#ffffff",
      margin: "0.5rem 0",
      padding: "0.8rem",
      borderRadius: "8px",
      border: "none",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      cursor: "grab",
      transition: "all 0.3s ease",
      '&:hover': {
        backgroundColor: "#F0F8FF",
        transform: "translateY(-5px)",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)"
      }
    }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Mail sx={{ color: " #666", mr: 1 }} />
          <Typography variant="body2" color="rgb(0, 0, 0)">
            {empEmail}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Person sx={{ color: "#666", mr: 1 }} />
          <Typography variant="body2" color="#000000">
            {empUserName}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AccessTime sx={{ color: "#666", mr: 1 }} />
          <Typography variant="body2" color="#000000">
            {new Date(createdTime).toLocaleString()}
          </Typography>
        </Box>

        {approvedBy && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Person sx={{ color: "#16a34a", mr: 1 }} />
              <Typography variant="body2" color="#16a34a">
                {approvedBy}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTime sx={{ color: "#16a34a", mr: 1 }} />
              <Typography variant="body2" color="#16a34a">
                {new Date(updatedTime).toLocaleString()}
              </Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

TaskCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    empEmail: PropTypes.string.isRequired,
    empUserName: PropTypes.string.isRequired,
    createdTime: PropTypes.string.isRequired,
    approvedBy: PropTypes.string,
    updatedTime: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string.isRequired
  }).isRequired
};

// Column Component with Prop Validation
const Column = ({ title, tasks }) => (
  <Paper sx={{
    width: '100%',
    minHeight: "100%",
    backgroundColor: "#F9FAFB",
    padding: "1.5rem",
    margin: "0.5rem",
    borderRadius: "12px",
    border: "1px solid #E0E0E0",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease",
    '&:hover': {
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)"
    }
  }}>
    <Typography
      variant="h6"
      sx={{
        color: " #1A73E8",
        mb: 2,
        textAlign: "center",
        fontWeight: "bold"
      }}
    >
      {title}
    </Typography>
    <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
      {tasks.map(task => (
        <TaskCard key={task.id} task={task} />
      ))}
    </Box>
  </Paper>
);

Column.propTypes = {
  title: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape(TaskCard.propTypes.task)).isRequired
};

// Main Board Component
const ControlledBoard = () => {
  const [columns, setColumns] = useState([]);
  const [lifeCycleStatus, setLifeCycleStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchExitLifeCycleStatusesPending = async (status) => {
    try {
      const url = `${APIData.api}employee/exit/pending/status-type?status=${status}&type=${exitKeyWord}&org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      return response.data;
    } catch (err) {
      toast.error("Failed to fetch status data");
      throw err;
    }
  };

  const fetchExitLifeCycleStatuses = async () => {
    setLoading(true);
    try {
      const url = `${APIData.api}employee/life-cycle/type?type=${exitKeyWord}&org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      const statuses = response.data || [];
      
      setLifeCycleStatus(statuses);
      
      const columnsData = await Promise.all(
        statuses.map(async (status) => {
          const tasks = await fetchExitLifeCycleStatusesPending(status.statusName);
          return {
            id: status.id,
            title: status.statusName,
            tasks
          };
        })
      );

      setColumns(columnsData.sort((a, b) => a.id - b.id));
    } catch (err) {
      setError(err.message);
      toast.error("Failed to fetch lifecycle statuses");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExitLifeCycleStatuses();
  }, []);

  if (error) {
    return (
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', color: 'error.main' }}>
        <AlertCircle style={{ height: "1rem", width: "1rem", marginRight: "0.5rem" }} />
        <Typography>Error loading board: {error}</Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      gap: 2,
      p: 2,
      overflowX: 'auto',
      backgroundColor: '#F9FAFB',
      borderRadius: '12px',
      border: '1px solid #E0E0E0'
    }}>
      {columns.map(column => (
        <Column 
          key={column.id}
          title={column.title}
          tasks={column.tasks}
        />
      ))}
    </Box>
  );
};

// Main Component
const DetailsForExit = () => (
  <PageLayout>
    <Box sx={{ p: 3 }}>
      <SoftBox p={3} mb={1} display="flex" alignItems="center">
        <Link to="/dashboard" style={{ textDecoration: 'none' }}>
          <AiIcons.AiOutlineCloseCircle />
        </Link>
        <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
          Employee Exit Board
        </SoftTypography>
      </SoftBox>
      <ControlledBoard />
    </Box>
  </PageLayout>
);

export default DetailsForExit;
