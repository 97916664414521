import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { Stepper, Step, StepLabel, Container, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import WorkIcon from '@mui/icons-material/Work';
import BadgeIcon from '@mui/icons-material/Badge';
import PropTypes from 'prop-types';
import SoftButton from 'components/SoftButton';
import AddJob from './Addjob';
import Add_designation from './Add_designation';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 14,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 28,
    height: 28,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
}));

const StepperContainer = styled(Box)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    padding: '10px',
    height: "150px",
    backgroundColor: "white"
}));

const MainContent = styled(Box)(({ theme }) => ({
    padding: '20px',
    width: '100%',
    minHeight: '100vh'
}));

function ColorlibStepIcon(props) {
    const { active, completed, icon } = props;
    const icons = {
        1: <WorkIcon />,
        2: <BadgeIcon />,
    };

    return (
        <ColorlibStepIconRoot>
            {icons[String(icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node.isRequired,
};

const steps = [
    'Add Designation',
    'Add Job',
];

const JobStepperPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(() => {
        const searchParams = new URLSearchParams(location.search);
        const step = searchParams.get('step');
        return step ? parseInt(step) : 0;
    });
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            setShowSuccessMessage(true);
            return;
        }
        const nextStep = activeStep + 1;
        setActiveStep(nextStep);
        navigate(`?step=${nextStep}`, { replace: true });
    };

    const handleBack = () => {
        const prevStep = activeStep - 1;
        setActiveStep(prevStep);
        navigate(`?step=${prevStep}`, { replace: true });
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <Add_designation />;
            case 1:
                return <AddJob />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <MainContent>
            <StepperContainer>
                <DashboardLayout>
                    <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </DashboardLayout>
            </StepperContainer>

            <Container>
                {showSuccessMessage ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '60vh',
                            textAlign: 'center',
                            // backgroundColor: '#f0f0f0',
                            padding: '20px',
                           
                        }}
                    >
                        <Typography variant="h4" color="success.main" sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
                        Designation and Job Setup Successfully Completed!
                        </Typography>
                        <SoftButton
                            onClick={() => { navigate('/jobEditButtons'); }}
                            variant="gradient"
                            color="info"
                            sx={{ padding: '10px 20px', borderRadius: '8px' }}
                        >
                            Go to Job Posting
                        </SoftButton>
                    </Box>
                ) : (
                    <>
                        {getStepContent(activeStep)}
                        <DashboardLayout>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                <SoftButton
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    variant="gradient"
                                    color="info"
                                >
                                    Back
                                </SoftButton>
                                <SoftButton
                                    onClick={handleNext}
                                    variant="gradient"
                                    color="info"
                                    disabled={isSubmitting}
                                >
                                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                </SoftButton>
                            </Box>
                        </DashboardLayout>
                    </>
                )}
            </Container>
        </MainContent>
    );
};

export default JobStepperPage;