import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SoftInput from 'components/SoftInput';

const CommentInput = ({ templates, value, onChange, comments = [] }) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const filtered = templates
      .flatMap((template) => template.split('.').map((point) => point.trim()))
      .filter((point) => point && point.toLowerCase().includes(value.toLowerCase()));
    setFilteredTemplates(filtered);
  }, [value, templates]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getCommentDifference = (currentComment, prevComment) => {
    if (!prevComment) return null;
    const words1 = currentComment.split(' ');
    const words2 = prevComment.split(' ');
    const added = words1.filter((word) => !words2.includes(word));
    const removed = words2.filter((word) => !words1.includes(word));
    return { added, removed };
  };

  // Inline styles
  const styles = {
    wrapper: {
      width: '100%',
      position: 'relative',
    },
    suggestions: {
      position: 'absolute',
      zIndex: 10,
      width: '100%',
      marginTop: '0.25rem',
      backgroundColor: 'white',
      border: '1px solid #e5e7eb',
      borderRadius: '0.375rem',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
      maxHeight: '15rem',
      overflowY: 'auto',
    },
    suggestionItem: {
      padding: '0.5rem 1rem',
      cursor: 'pointer',
      fontSize: '0.875rem',
    },
    suggestionItemHover: {
      backgroundColor: '#f3f4f6',
    },
    comments: {
      marginTop: '1rem',
      border: '1px solid #e5e7eb',
      borderRadius: '0.375rem',
      maxHeight: '15rem',
      overflowY: 'auto',
    },
    commentItem: {
      padding: '0.75rem',
      borderBottom: '1px solid #e5e7eb',
    },
    commentDiff: {
      marginTop: '0.5rem',
      fontSize: '0.75rem',
    },
    commentDiffAdded: {
      color: '#10b981',
    },
    commentDiffRemoved: {
      color: '#ef4444',
    },
  };

  return (
    <div ref={wrapperRef} style={styles.wrapper}>
      <SoftInput
        placeholder="Start typing or select a template..."
        multiline
        minRows={4}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
          setShowSuggestions(true);
        }}
        onFocus={() => setShowSuggestions(true)}
        style={{ width: '100%' }}
      />

      {showSuggestions && filteredTemplates.length > 0 && (
        <div style={styles.suggestions}>
          {filteredTemplates.map((template, index) => (
            <div
              key={index}
              style={styles.suggestionItem}
              onMouseEnter={(e) => (e.target.style.backgroundColor = styles.suggestionItemHover.backgroundColor)}
              onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}
              onClick={() => {
                onChange(template);
                setShowSuggestions(false);
              }}
            >
              {template}
            </div>
          ))}
        </div>
      )}

      <div style={styles.comments}>
        {comments.map((comment, index) => {
          const diff = getCommentDifference(comment, comments[index - 1]);
          return (
            <div key={index} style={styles.commentItem}>
              <div>{comment}</div>
              {diff && (diff.added.length > 0 || diff.removed.length > 0) && (
                <div style={styles.commentDiff}>
                  {diff.added.length > 0 && (
                    <div style={styles.commentDiffAdded}>
                      Added: {diff.added.join(', ')}
                    </div>
                  )}
                  {diff.removed.length > 0 && (
                    <div style={styles.commentDiffRemoved}>
                      Removed: {diff.removed.join(', ')}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

CommentInput.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(PropTypes.string),
};

export default CommentInput;
