import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "examples/Tables/Table";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { APIData, org } from "../authentication/APIData";
import { Camera, Video } from 'lucide-react';
import CandidateEnrolled from './CandidateEnrolled';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import Swal from 'sweetalert2';
import SoftInput from 'components/SoftInput';
import { DialogContent } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import CommentInput from './CommentInput';


const Name = ({ name, email }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
                {email}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
};

const CandidateData = () => {
    const [status, setStatus] = useState('APPLIED');
    const [candidates, setCandidates] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [enrollDialogOpen, setEnrollDialogOpen] = useState(false);
    const [enrollCandidate, setEnrollCandidate] = useState(null);
    const [interviewers, setInterviewers] = useState({});
    const [resumeDialogOpen, setResumeDialogOpen] = useState(false);
    const [resumeData, setResumeData] = useState(null);
    const [newStatus, setNewStatus] = useState("");
    const [department, setDepartment] = useState("");
    const [interviewer, setInterviewer] = useState("");
    const [comments, setComments] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [selectedComment, setSelectedComment] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [templates, setTemplates] = useState([]);
    const [openModal, setOpenModal] = useState(false); // Modal state


    const filteredCandidates = candidates.filter(candidate =>
        candidate.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.candidateEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.dept?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.role?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.candidateType?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.candidateStatus?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedCandidates = filteredCandidates.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const statusCategories = [
        { value: 'APPLIED', label: 'Applied', index: 0 },
        { value: 'SCRUTINTY', label: 'Scrutiny', index: 1 },
        { value: 'NEXTROUND', label: 'Next Round', index: 2 },
        { value: 'FINALROUND', label: 'Final Round', index: 3 },
        { value: 'OFFERRELEASED', label: 'Offer Released', index: 4 },
        { value: 'REJECTED', label: 'Rejected', index: 5 }
    ];

    useEffect(() => {
        function handleTabsOrientation() {
            return window.innerWidth < 768
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        window.addEventListener("resize", handleTabsOrientation);
        handleTabsOrientation();
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, []);

    const fetchCandidates = (currentStatus) => {
        setLoading(true);
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        const department = localStorage.getItem('Depart Details');
        let url;
        if (sessionDetails.userType === "SUPERADMIN" || department === "HR") {
            url = `${APIData.api}candidate/status?status=${currentStatus}&org=${org}`;
        } else {
            url = `${APIData.api}candidate/dept/interviewer?status=${currentStatus}&dept=${department}&intvEmailId=${sessionDetails.email}&org=${org}`;
        }
        axios.get(url, { headers: APIData.headers })
            .then((response) => {
                if (response.data) {
                    setCandidates(response.data);
                } else {
                    console.log('No candidates found');
                    setCandidates([]);
                }
            })
            .catch((error) => {
                console.error('API call failed:', error);
                setCandidates([]);
                toast.error('Failed to fetch candidates');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const fetchTemplates = async (email, org, round) => {
        try {
            const response = await axios.get(
               `${APIData.api}automated/templates/getTemplatesByEmailAndOrg`,
                {
                    params: { email, org, round },
                    headers: APIData.headers,
                }
            );
            const filteredTemplates = response.data.filter(template => {
                return template.toLowerCase().includes(round.toLowerCase());
            });
            const comments = filteredTemplates.map(template => {
                const commentMatch = template.match(/Comments:[\s\S]+/i);
                return commentMatch ? commentMatch[0].replace("Comments:", "").trim() : "No comments available";
            });
            setTemplates(comments);  // Set only the comments in the state
        } catch (error) {
            console.error("Error fetching templates:", error);
            toast.error("Failed to fetch templates.");
            setTemplates([]); // Set empty templates in case of error
        }
    };

    const handleTemplateSelect = (template) => {
        setComments(template); // Set the selected template as the comment
        setErrors((prev) => ({ ...prev, comments: "" })); // Clear any existing errors
    };

    useEffect(() => {

        fetchCandidates(status);

    }, [status]);

    const fetchInterviewers = async (dept) => {
        try {
            const url = `${APIData.api}employee/email?department=${dept}&org=${org}`;
            const response = await axios.get(url, { headers: APIData.headers });

            if (response.data && response.data.length > 0) {
                setInterviewers(prev => ({ ...prev, [dept]: response.data }));
            } else {
                const secondUrl = `${APIData.api}employee/details?org=${org}`;
                const secondResponse = await axios.get(secondUrl, { headers: APIData.headers });

                if (secondResponse.data && secondResponse.data.length > 0) {
                    const interviewerEmails = secondResponse.data.map(employee => employee.email);
                    setInterviewers(prev => ({ ...prev, [dept]: interviewerEmails }));
                } else {
                    setInterviewers(prev => ({ ...prev, [dept]: [] }));
                }
            }
        } catch (error) {
            toast.error(`Error fetching interviewers for department ${dept}`);
            setInterviewers(prev => ({ ...prev, [dept]: [] }));
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setStatus(statusCategories[newValue].value);
    };

    const handleDialogOpen = (candidate) => {
        setSelectedCandidate(candidate);
        setDepartment(candidate.dept || '');
        setNewStatus(candidate.candidateStatus);
        setInterviewer('');
        setComments('');
        setDialogOpen(true);

        const org = candidate.org || '';  // Ensure org is populated
        const status = candidate.candidateStatus; // Use status as fallback
        fetchTemplates(candidate.candidateEmail, org, status);

        if (candidate.dept) {
            fetchInterviewers(candidate.dept);
        }
    };
    const handleOpenModal = () => {
        setOpenModal(true); // Open modal when the user focuses or types
    };

    const handleCloseModal = () => {
        console.log("closinggg")
        setOpenModal(false); // Close modal

    };
    const handleSelectComment = (comment) => {
        setComments(comment); // Set the selected comment
        setSelectedComment(comment);
        handleCloseModal(); // Close modal after selection
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedCandidate(null);
        setNewStatus("");
        setDepartment("");
        setInterviewer("");
        setComments("");
        setErrors({
            interviewer: '',
            comments: ''
        });
    };

    const handleDepartmentChange = (event) => {
        const dept = event.target.value;
        setDepartment(dept);
        setInterviewer('');
        if (!interviewers[dept]) {
            fetchInterviewers(dept);
        }
    };

    useEffect(() => {
        if (selectedCandidate && selectedCandidate.dept) {
            setDepartment(selectedCandidate.dept);
            fetchInterviewers(selectedCandidate.dept);
        }
    }, [selectedCandidate]);

    const [errors, setErrors] = useState({
        interviewer: '',
        comments: ''
    });

    const validateFields = () => {
        const newErrors = {
            interviewer: '',
            comments: ''
        };

        let isValid = true;

        if (!interviewer) {
            newErrors.interviewer = `Please select ${newStatus === "OFFERRELEASED" ? "an allocated person" : "an interviewer"}`;
            isValid = false;
        }

        if (!comments.trim()) {
            newErrors.comments = 'Please add comments';
            isValid = false;
        } else if (comments.trim().length < 10) {
            newErrors.comments = 'Comments should be at least 10 characters long';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (!validateFields()) {
            return;
        }
        setLoading(true);
        try {
            const url = `${APIData.api}candidate/%7Bid%7D`;
            const data = { comments };
            const headers = {
                ...APIData.headers,
                status: newStatus,
                enroll: "NO",
                emailId: selectedCandidate.candidateEmail,
                interviewer,
                hrSpoc: "poornima.r@care4edu.com"
            };

            await axios.put(url, data, { headers });
            Swal.fire({
                icon: "success",
                title: "Data updated successfully",
                showConfirmButton: false,
                timer: 1500
            });
            handleDialogClose();
            fetchCandidates(status);
        } catch (error) {
            handleDialogClose(false);
            Swal.fire({
                icon: "error",
                title: "Oops!",
                text: "Error updating data",
                footer: error
            });
        } finally {
            setLoading(false);
        }
    };

    const handleEnrollDialogOpen = (candidate) => {
        setEnrollCandidate(candidate);
        setEnrollDialogOpen(true);
    };
    const handleResumeDialogOpen = async (selectedCandidate) => {
        try {
            const url = `${APIData.api}resumes/email?email=${selectedCandidate.candidateEmail}`;
            const response = await axios.get(url, { headers: APIData.headers });
            console.log(response.data);
            const resume = response.data[0]
            console.log(resume.resume_url);

            setResumeData(resume.resume_url);
            setResumeDialogOpen(true);
        } catch (error) {
            console.error('Error fetching resume:', error);
            toast.error('Failed to fetch resume');
        }
    };

    const handleResumeDialogClose = () => {
        setResumeDialogOpen(false);
        setResumeData(null);
    };


    const columns = [
        { name: "Name", align: "left" },
        { name: "Type", align: "center" },
        { name: "Status", align: "center" },
        { name: "Department", align: "center" },
        { name: "Role", align: "center" },
        { name: "Action", align: "center" }
    ];

    const rows = paginatedCandidates.map((candidate) => ({
        Name: <Name name={candidate.firstName} email={candidate.candidateEmail} />,
        Type: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {candidate.candidateType}
            </SoftTypography>
        ),
        Status: (
            <SoftBadge
                variant="gradient"
                badgeContent={candidate.candidateStatus.toLowerCase()}
                color={candidate.candidateStatus === "OFFERRELEASED" ? "success" : "secondary"}
                size="xs"
                container
            />
        ),
        Department: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {candidate.dept}
            </SoftTypography>
        ),
        Role: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {candidate.role}
            </SoftTypography>
        ),
        Action: (
            <SoftBox display="flex" gap={2} justifyContent="center">
                {/* <Link to={`/candidateInterview?email=${candidate.candidateEmail}`}>
                    <SoftButton
                        variant="text"
                        color="info"
                        iconOnly
                    >
                        <Video size={20} />
                    </SoftButton>
                </Link> */}
                {candidate.candidateStatus === 'OFFERRELEASED' ?
                    (
                        candidate.enroll === 'YES' ? (

                            <SoftButton
                                variant="text"
                                color="success"
                                disabled>
                                Enrolled
                            </SoftButton>
                        ) :
                            (
                                <>
                                    <Link to={`/candidateInterview?email=${candidate.candidateEmail}`}>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            iconOnly
                                        >
                                            <Video size={20} />
                                        </SoftButton>
                                    </Link>
                                    <SoftButton
                                        variant="text"
                                        color="success"
                                        onClick={() => handleEnrollDialogOpen(candidate)}
                                    >
                                        Enroll
                                    </SoftButton>
                                </>
                            )
                    ) : (
                        <SoftButton
                            variant="text"
                            color="primary"
                            onClick={() => handleDialogOpen(candidate)}
                        >
                            Edit
                        </SoftButton>
                    )}
            </SoftBox>
        )
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={6} lg={16} sx={{ ml: "0" }}>
                                <AppBar position="static">
                                    <Tabs
                                        orientation={tabsOrientation}
                                        value={tabValue}
                                        onChange={handleTabChange}
                                        sx={{ background: "transparent" }}
                                    >
                                        {statusCategories.map((category) => (
                                            <Tab
                                                key={category.value}
                                                label={category.label}
                                                sx={{
                                                    color: tabValue === category.index ? "primary.main" : "text.secondary",
                                                    fontWeight: tabValue === category.index ? "bold" : "normal",
                                                    borderBottom: tabValue === category.index ? "2px solid" : "none",
                                                    borderColor: "info.main",
                                                }}
                                            />
                                        ))}
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Card>
                    <SoftBox mt={2}>
                        <Card>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <SoftTypography variant="h6">Candidates Onboard</SoftTypography>
                                <SoftBox>
                                    <SoftInput
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            setPage(0); // Reset to first page when searching
                                        }}
                                        size="small"
                                    />
                                </SoftBox>
                            </SoftBox>
                            {loading ? (
                                <SoftBox display="flex" justifyContent="center">
                                    <SoftTypography>Loading...</SoftTypography>
                                </SoftBox>
                            ) : (
                                <SoftBox
                                    sx={{
                                        "& .MuiTableRow-root:not(:last-child)": {
                                            "& td": {
                                                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                    `${borderWidth[1]} solid ${borderColor}`,
                                            },
                                        },
                                    }}
                                >
                                    <Table columns={columns} rows={rows} />
                                    <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        p={3}
                                    >
                                        <SoftBox display="flex" gap={1} alignItems="center">
                                            <SoftTypography variant="caption" color="secondary">
                                                {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredCandidates.length)} of {filteredCandidates.length}
                                            </SoftTypography>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                                disabled={page === 0}
                                                iconOnly
                                            >
                                                <KeyboardArrowLeft />
                                            </SoftButton>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.min(Math.ceil(filteredCandidates.length / itemsPerPage) - 1, prev + 1))}
                                                disabled={page >= Math.ceil(filteredCandidates.length / itemsPerPage) - 1}
                                                iconOnly
                                            >
                                                <KeyboardArrowRight />
                                            </SoftButton>
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>
                            )}
                        </Card>
                    </SoftBox>
                </SoftBox>
            </SoftBox>

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                maxWidth="md"
                PaperProps={{
                    style: {
                        borderRadius: "10px",
                        padding: "16px"
                    }
                }}
            >
                <SoftBox p={3}>
                    <SoftTypography
                        variant="h4"
                        textAlign="center"
                        mb={2}
                        fontWeight="medium"
                    >
                        Status Update Of <strong>{selectedCandidate?.firstName} {selectedCandidate?.lastName}</strong>
                    </SoftTypography>

                    <Grid container spacing={3}>
                        {/* Candidate Info Section */}
                        <Grid item xs={12}>
                            <SoftBox mb={2}>
                                <Card>
                                    <SoftBox p={2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    Email:
                                                </SoftTypography>
                                                <SoftTypography variant="button" fontWeight="regular">
                                                    {selectedCandidate?.candidateEmail}
                                                </SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    Type:
                                                </SoftTypography>
                                                <SoftTypography variant="button" fontWeight="regular">
                                                    {selectedCandidate?.candidateType}
                                                </SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    Phone:
                                                </SoftTypography>
                                                <SoftTypography variant="button" fontWeight="regular">
                                                    {selectedCandidate?.candidatePhNo}
                                                </SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    Role:
                                                </SoftTypography>
                                                <SoftTypography variant="button" fontWeight="regular">
                                                    {selectedCandidate?.role}
                                                </SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    Department:
                                                </SoftTypography>
                                                <SoftTypography variant="button" fontWeight="regular">
                                                    {selectedCandidate?.dept}
                                                </SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    Designation:
                                                </SoftTypography>
                                                <SoftTypography variant="button" fontWeight="regular">
                                                    {selectedCandidate?.designation}
                                                </SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    Qualification:
                                                </SoftTypography>
                                                <SoftTypography variant="button" fontWeight="regular">
                                                    {selectedCandidate?.highestQualification}
                                                </SoftTypography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                                    Current Status:
                                                </SoftTypography>
                                                <SoftBadge
                                                    variant="gradient"
                                                    badgeContent={selectedCandidate?.candidateStatus.toLowerCase()}
                                                    color="info"
                                                    size="xs"
                                                    container
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SoftButton color="light" onClick={() => handleResumeDialogOpen(selectedCandidate)}>View Resume</SoftButton>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Link to={`/candidateInterview?email=${selectedCandidate?.candidateEmail}`}>
                                                    <SoftButton color="success">
                                                        <pre>schedule Interview </pre><Video size={20} />
                                                    </SoftButton>
                                                </Link>
                                            </Grid>

                                        </Grid>
                                    </SoftBox>
                                </Card>
                            </SoftBox>
                        </Grid>

                        {/* Status Update Section */}
                        <Grid item xs={12} md={4}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h6" mb={1}>Update Status</SoftTypography>
                                <CustomSelect
                                    options={statusCategories.map(status => ({
                                        value: status.value,
                                        label: status.label
                                    }))}
                                    value={newStatus}
                                    onChange={(selectedOption) => setNewStatus(selectedOption.value)}
                                    placeholder="Select Status"
                                    defaultValue={selectedCandidate?.candidateStatus ? {
                                        value: selectedCandidate.candidateStatus,
                                        label: statusCategories.find(s => s.value === selectedCandidate.candidateStatus)?.label
                                    } : null}
                                />
                            </SoftBox>
                        </Grid>

                        {/* Department Dropdown */}
                        <Grid item xs={12} md={4}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h6" mb={1}>Department</SoftTypography>
                                <CustomSelect
                                    options={[
                                        { value: "HR", label: "HR" },
                                        { value: "SALES", label: "SALES" },
                                        { value: "ACADEMY", label: "ACADEMY" },
                                        { value: "FINANCE", label: "FINANCE" },
                                        { value: "TECHNICAL", label: "TECHNICAL" }
                                    ]}
                                    value={department}
                                    onChange={(selectedOption) => handleDepartmentChange({ target: { value: selectedOption.value } })}
                                    placeholder="Select Department"
                                    defaultValue={selectedCandidate?.dept ? {
                                        value: selectedCandidate.dept,
                                        label: selectedCandidate.dept
                                    } : null}
                                />
                            </SoftBox>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h6" mb={1}>
                                    {newStatus === "OFFERRELEASED" ? "Allocated Person" : "Interviewer"}
                                </SoftTypography>
                                <CustomSelect
                                    options={interviewers[department]?.map(email => ({
                                        value: email,
                                        label: email
                                    })) || []}
                                    value={interviewer}
                                    onChange={(selectedOption) => {
                                        setInterviewer(selectedOption.value);
                                        setErrors(prev => ({ ...prev, interviewer: '' }));
                                    }}
                                    placeholder={`Select ${newStatus === "OFFERRELEASED" ? "Allocated Person" : "Interviewer"}`}
                                />
                                {errors.interviewer && (
                                    <SoftTypography variant="caption" color="error" fontWeight="regular">
                                        {errors.interviewer}
                                    </SoftTypography>
                                )}
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="caption" fontWeight="medium" color="text">
                                    Previous Comments:
                                </SoftTypography>
                                <SoftTypography variant="button" fontWeight="regular">
                                    {selectedCandidate?.intComments
                                        ? selectedCandidate.intComments
                                            .replace(/[/\":*;{}]/g, '') // Remove special characters "", {}
                                            .split('comments')
                                            .map((line, index) => (
                                                <span key={index}>{line.trim()}<br /></span>
                                            ))
                                        : "No previous comments"}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h6" mb={1}>
                                    Add New Comment
                                </SoftTypography>
                                <CommentInput
                                    templates={templates}
                                    value={comments}
                                    onChange={(value) => {
                                        setComments(value);
                                        setErrors((prev) => ({ ...prev, comments: '' }));
                                    }}
                                />
                            </SoftBox>
                        </Grid>
                    </Grid>

                    {/* Action Buttons */}
                    <Grid item xs={12}>
                        <SoftBox mt={4} mb={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SoftButton fullWidth color="secondary" onClick={handleDialogClose}>
                                        Cancel
                                    </SoftButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <SoftButton fullWidth color="info" onClick={handleSubmit}>
                                        Submit
                                    </SoftButton>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Grid>
                </SoftBox>
                <Backdrop open={loading} className="z-9999">
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
            {/* Enroll Dialog */}
            <Dialog
                open={enrollDialogOpen}
                onClose={() => setEnrollDialogOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle style={{ textAlign: "center", fontFamily: "Georgia, serif" }}>
                    Enroll {enrollCandidate?.firstName} {enrollCandidate?.lastName}
                </DialogTitle>
                <CandidateEnrolled
                    enrollCandidate={enrollCandidate}
                    setEnrollDialogOpen={setEnrollDialogOpen}
                />
            </Dialog>
            <Dialog
                open={resumeDialogOpen}
                onClose={handleResumeDialogClose}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle style={{ textAlign: "center", fontFamily: "Georgia, serif" }}>
                    Resume of {selectedCandidate?.firstName} {selectedCandidate?.lastName}
                </DialogTitle>
                <DialogContent>
                    <iframe
                        src={`data:application/pdf;base64,${resumeData}`}
                        style={{ width: "100%", height: "600px" }}
                        title="Resume"
                    />



                </DialogContent>
            </Dialog>
            <Footer />
        </DashboardLayout>
    );
};

export default CandidateData;