import React, { useEffect, useState } from 'react';
import { APIData, org } from 'authentication/APIData';
import axios from 'axios';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    Switch,
    Box,
    CircularProgress,
    AppBar,
    Tabs,
    Tab
} from '@mui/material';
import Footer from 'examples/Footer';
import Swal from 'sweetalert2';
import SoftAvatar from 'components/SoftAvatar';

const Members = () => {
    const [data, setData] = useState([]);
    const [statusFilter, setStatusFilter] = useState("ALL");
    const [loadingEmployees, setLoadingEmployees] = useState({});
    const [visiblePhones, setVisiblePhones] = useState({});
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [tabValue, setTabValue] = useState(0); // For managing tabs

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
    }, []);

    const togglePhoneVisibility = (employeeId) => {
        setVisiblePhones((prev) => ({
            ...prev,
            [employeeId]: !prev[employeeId],
        }));
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue); // Set the tab value
        const departments = ['ALL', 'HR', 'TECHNICAL', 'FINANCE', 'SALES', 'ACADEMY'];
        setStatusFilter(departments[newValue]); // Update status filter based on selected tab
    };

    const fetchData = (filter) => {
        const url = filter === 'ALL'
            ? `employee/activeEmployees?org=${org}`
            : `employee/empdetailsByDepartment?department=${filter}&org=${org}`;
        axios
            .get(APIData.api + url, { headers: APIData.headers })
            .then((resp) => {
                setData(resp.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };


    const updateWorkType = (employeeId, workType) => {
                Swal.fire({
                    title: 'Are you sure?',
                    text: `You are about to change the work type to ${workType} for employee ID: ${employeeId}.`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, change it!',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setLoadingEmployees((prev) => ({ ...prev, [employeeId]: true }));
                        const url = `${APIData.api}employee/${employeeId}/worktype`;
        
                        axios
                            .put(url, null, {
                                params: {
                                    workType: workType,
                                },
                                headers: {
                                    ...APIData.headers,
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                },
                            })
                            .then((response) => {
                                setData((prevData) =>
                                    prevData.map((employee) =>
                                        employee.employee_id === employeeId
                                            ? { ...employee, workType: workType }
                                            : employee
                                    )
                                );
                                Swal.fire({
                                    title: 'Success!',
                                    text: 'Work type has been updated successfully.',
                                    icon: 'success',
                                    confirmButtonText: 'OK',
                                });
                            })
                            .catch((error) => {
                                console.error('Error updating workType:', error);
                                Swal.fire({
                                    title: 'Error!',
                                    text: `Failed to update work type: ${error.message}`,
                                    icon: 'error',
                                    confirmButtonText: 'OK',
                                });
                            })
                            .finally(() => {
                                setLoadingEmployees((prev) => {
                                    const newState = { ...prev };
                                    delete newState[employeeId];
                                    return newState;
                                });
                            });
                    }
                });
            };

    useEffect(() => {
        fetchData(statusFilter);
    }, [statusFilter]);
    const handleWorkTypeToggle = (employee) => {
                const newWorkType = employee.workType === 'WORK_FROM_OFFICE'
                    ? 'WORK_FROM_HOME'
                    : 'WORK_FROM_OFFICE';
                updateWorkType(employee.employee_id, newWorkType);
            };

    const generateAvatarStyle = (name) => {
        const colors = [
            { bg: '#007a99', text: '#ffffff' },
            { bg: '#5a67d8', text: '#ffffff' },
            { bg: '#38a169', text: '#ffffff' },
            { bg: '#d53f8c', text: '#ffffff' },
            { bg: '#e53e3e', text: '#ffffff' }
        ];
        return colors[name.charCodeAt(0) % colors.length];
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3} px={3}>
                <SoftBox mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SoftTypography variant="h3" fontWeight="bold">Team Members</SoftTypography>
                </SoftBox>

                {/* Tabs with Transitions */}
                <AppBar position="static" color="default">
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="Department Tabs"
                        sx={{
                            background: "transparent",
                            borderBottom: "2px solid #ddd",
                            "& .MuiTab-root": {
                                textTransform: "none",
                                fontWeight: "600",
                                color: "text.secondary",
                                fontSize: "1rem",
                                transition: "color 0.3s, border-bottom 0.3s",
                            },
                            "& .MuiTab-root.Mui-selected": {
                                color: "info.main",
                                fontWeight: "bold",
                                borderBottom: "2px solid",
                                borderColor: "info.main",
                            },
                        }}
                    >
                        <Tab label="ALL" />
                        <Tab label="HR" />
                        <Tab label="Technical" />
                        <Tab label="Finance" />
                        <Tab label="Sales" />
                        <Tab label="Academics" />
                    </Tabs>
                </AppBar>
            </SoftBox>

            <SoftBox mt={4} mb={3} px={3}>
                {data.length === 0 ? (
                    <Typography
                        variant="h6"
                        align="center"
                        sx={{ color: 'text.secondary', fontWeight: 500 }}
                    >
                        No members found for this department. Please select another department or &quot;ALL&quot; to view all members.
                    </Typography>
                ) : (
                    <Grid container spacing={4}>
                        {data.map((employee) => {
                            const avatarStyle = generateAvatarStyle(employee.name);
                            return (
                                <Grid item xs={12} sm={6} md={4} key={employee.employee_id}>
                                    <Card
                                        sx={{
                                            height: '100%',
                                            border: '1px solid #e0e0e0',
                                            borderRadius: '12px',
                                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                            '&:hover': {
                                                boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                                            },
                                        }}
                                    >
                                        <CardContent sx={{ position: 'relative', pb: 3 }}>
                                            <SoftBox display="flex" alignItems="center" mt={1} mb={2} sx={{
                                                backgroundColor: 'rgb(142, 197, 231)',
                                                color: 'white',
                                                padding: '8px 16px',
                                                borderRadius: '12px 12px 0 0',
                                            }}>
                                                <SoftAvatar
                                                    src={employee.photoUrl || ''}
                                                    style={{
                                                        backgroundColor: employee.image ? undefined : avatarStyle.bg,
                                                        color: employee.image ? undefined : avatarStyle.text,
                                                        width: 60,
                                                        height: 60,
                                                        fontSize: '2rem',
                                                        fontWeight: 600,
                                                        ...((!employee.image) && {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }),
                                                    }}
                                                >
                                                    {!employee.image && employee.name.charAt(0).toUpperCase()}
                                                </SoftAvatar>
                                                <SoftBox ml={2}>
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: ' white',
                                                            fontSize: '1.1rem',
                                                        }}
                                                    >
                                                        {employee.name}
                                                    </Typography>
                                                </SoftBox>
                                            </SoftBox>

                                            <Box sx={{
                                                backgroundColor: ' #f4f7fe',
                                                borderRadius: 2,
                                                p: 2,
                                                mb: 2,
                                            }}>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    <strong>Email:</strong> {employee.email}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    <strong>Phone:</strong>
                                                    {visiblePhones[employee.employee_id]
                                                        ? ` ${employee.phone_number}`
                                                        : ` *****${employee.phone_number.slice(-4)}`}
                                                    {(permission === "1111" || isSuperAdmin) ? <Box
                                                        component="span"
                                                        onClick={() => togglePhoneVisibility(employee.employee_id)}
                                                        sx={{ ml: 1, cursor: 'pointer', verticalAlign: 'middle' }}
                                                    >
                                                        {visiblePhones[employee.employee_id]
                                                            ? <VisibilityOff fontSize="small" />
                                                            : <Visibility fontSize="small" />
                                                        }
                                                    </Box> : ""}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    <strong>Gender:</strong> {employee.gender}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    <strong>Qualification:</strong> {employee.qualification}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    <strong>Joined Date:</strong> {employee.year_of_appointment}
                                                </Typography>
                                            </Box>

                                            {(permission === "1111" || isSuperAdmin) ? <Box mt={2} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: employee.workType === 'WORK_FROM_HOME' ? 'info' : 'text.secondary'
                                                    }}
                                                >
                                                    Work From Home
                                                </Typography>
                                                <Box sx={{ position: 'relative' }}>
                                                    <Switch
                                                        checked={employee.workType === 'WORK_FROM_HOME'}
                                                        onChange={() => handleWorkTypeToggle(employee)}
                                                        disabled={loadingEmployees[employee.employee_id]}
                                                        inputProps={{ 'aria-label': 'Toggle Work Type' }}
                                                    />
                                                    {loadingEmployees[employee.employee_id] && (
                                                        <CircularProgress
                                                            size={24}
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                marginTop: '-12px',
                                                                marginLeft: '-12px',
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </Box> : ""}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default Members;