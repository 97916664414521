import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    IconButton,
    CircularProgress,
    Snackbar,
    Alert,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel
} from '@mui/material';
import {
    Send as SendIcon,
    AttachFile as AttachFileIcon,
    ArrowBack as BackIcon
} from '@mui/icons-material';
import { APIData, org } from 'authentication/APIData';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import PropTypes from 'prop-types';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { Link } from 'react-router-dom';

const MassNotification = ({ onBack }) => {
    const [notificationType, setNotificationType] = useState('grade');
    const [massNotificationData, setMassNotificationData] = useState({
        grade: null,
        comments: '',
        mentorFile: null,
        selectedStudents: []
    });
    const [gradeOptions, setGradeOptions] = useState([]);
    const [studentOptions, setStudentOptions] = useState([]);
    const [loadingCourses, setLoadingCourses] = useState(false);
    const [loadingStudents, setLoadingStudents] = useState(false);
    const [sendingMassNotification, setSendingMassNotification] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const massNotificationFileRef = React.useRef(null);

    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails") || '{}');
    const email = sessionDetails?.email || '';

    useEffect(() => {
        fetchGradeOptions();
    }, []);

    useEffect(() => {
        if (notificationType === 'student' && massNotificationData.grade) {
            fetchStudents(massNotificationData.grade);
        }
    }, [notificationType, massNotificationData.grade]);

    const fetchGradeOptions = async () => {
        try {
            setLoadingCourses(true);
            const response = await fetch(`${APIData.api}studentadmissiondetails/classes?org=${org}`, {
                method: 'GET',
                headers: APIData.headers
            });

            if (!response.ok) throw new Error('Failed to fetch grade options');

            const data = await response.json();
            const options = data.map(grade => ({
                value: grade,
                label: grade
            }));
            setGradeOptions(options);
        } catch (error) {
            console.error('Error fetching grade options:', error);
            setError('Failed to load grade options. Please try again.');
        } finally {
            setLoadingCourses(false);
        }
    };

    const fetchStudents = async (grade) => {
        try {
            setLoadingStudents(true);
            const encodedGrade = encodeURIComponent(grade);
            const encodedEmail = encodeURIComponent(email);
            const response = await fetch(
                `${APIData.api}student/updates/students-emails?grade=${encodedGrade}&mentor=${encodedEmail}&org=${org}`,
                {
                    method: 'GET',
                    headers: APIData.headers
                }
            );

            if (!response.ok) throw new Error('Failed to fetch students');

            const data = await response.json();
            // Handle the data as an array of objects with name and emailId
            const options = Array.isArray(data) ? data.map(student => ({
                value: student.emailId, // Using emailId as the value
                label: student.name    // Using name as the display label
            })) : [];
            
            setStudentOptions(options);
        } catch (error) {
            console.error('Error fetching students:', error);
            setError('Failed to load students. Please try again.');
        } finally {
            setLoadingStudents(false);
        }
    };

    const handleNotificationTypeChange = (event) => {
        setNotificationType(event.target.value);
        setMassNotificationData(prev => ({
            ...prev,
            selectedStudents: []
        }));
    };

    const handleSelectChange = (selectedOption) => {
        setMassNotificationData(prev => ({
            ...prev,
            grade: selectedOption ? selectedOption.value : null,
            selectedStudents: []
        }));
    };

    const handleStudentSelect = (selectedOptions) => {
        setMassNotificationData(prev => ({
            ...prev,
            selectedStudents: selectedOptions || []
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMassNotificationData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleMassNotificationFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
            if (!validTypes.includes(file.type)) {
                setError('Please select only image files (JPEG, PNG, GIF)');
                return;
            }
            if (file.size > 5 * 1024 * 1024) {
                setError('File size should not exceed 5MB');
                return;
            }
            setMassNotificationData(prev => ({
                ...prev,
                mentorFile: file
            }));
        }
    };

    const handleSendMassNotification = async () => {
        if (!massNotificationData.grade || !massNotificationData.comments) {
            setError('Please fill in all required fields');
            return;
        }

        if (notificationType === 'student' && massNotificationData.selectedStudents.length === 0) {
            setError('Please select at least one student');
            return;
        }

        try {
            setSendingMassNotification(true);
            const formData = new FormData();
            formData.append('grade', massNotificationData.grade);
            formData.append('org', org);
            formData.append('comments', massNotificationData.comments);
            formData.append('mentorId', email);

            if (notificationType === 'student') {
                // Now correctly using emailId values from selected students
                const studentIds = massNotificationData.selectedStudents.map(student => student.value).join(',');
                formData.append('selectedStudentIds', studentIds);
            }

            if (massNotificationData.mentorFile) {
                formData.append('mentorFile', massNotificationData.mentorFile);
            }

            const headers = { ...APIData.headers };
            delete headers['Content-Type'];

            const endpoint = notificationType === 'student' 
                ? `${APIData.api}student/updates/bulk_create`
                : `${APIData.api}student/updates/bulk-create`;

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: headers,
                body: formData
            });

            if (!response.ok) {
                throw new Error(`Failed to send mass notification: ${response.status}`);
            }

            setSuccessMessage('Mass notification sent successfully!');
            setMassNotificationData({
                grade: null,
                comments: '',
                mentorFile: null,
                selectedStudents: []
            });
            massNotificationFileRef.current.value = '';
        } catch (error) {
            console.error('Error sending mass notification:', error);
            setError('Failed to send mass notification. Please try again.');
        } finally {
            setSendingMassNotification(false);
        }
    };

    const handleCloseSnackbar = () => {
        setError('');
        setSuccessMessage('');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box sx={{
                maxWidth: '700px',
                margin: 'auto',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                p: 2,
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                mt: 6
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <IconButton>
                        <Link to="/mentorChat">
                            <BackIcon />
                        </Link>
                    </IconButton>
                    <SoftTypography variant="h5">Send Mass Notification</SoftTypography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FormControl component="fieldset">
                        <SoftTypography variant="h5">Notification Type</SoftTypography>
                        <RadioGroup
                            row
                            value={notificationType}
                            onChange={handleNotificationTypeChange}
                        >
                            <FormControlLabel 
                                value="grade" 
                                control={<Radio />} 
                                label="Grade-wise" 
                                style={{marginLeft:"5px"}}
                            />
                            <FormControlLabel 
                                value="student" 
                                control={<Radio />} 
                                label="Student-wise" 
                            />
                        </RadioGroup>
                    </FormControl>

                    <SoftTypography variant="h6">Grade</SoftTypography>
                    <CustomSelect
                        options={gradeOptions}
                        placeholder="Select Grade"
                        onChange={handleSelectChange}
                        value={gradeOptions.find(option => option.value === massNotificationData.grade) || null}
                        error={false}
                        isLoading={loadingCourses}
                    />

                    {notificationType === 'student' && massNotificationData.grade && (
                        <Box sx={{ mt: 2 }}>
                            <SoftTypography variant="h6">Select Students</SoftTypography>
                            <CustomSelect
                                isMulti
                                options={studentOptions}
                                value={massNotificationData.selectedStudents}
                                onChange={handleStudentSelect}
                                isLoading={loadingStudents}
                                placeholder="Select students..."
                                error={false}
                            />
                        </Box>
                    )}

                    <SoftInput
                        fullWidth
                        multiline
                        placeholder="Type Message here"
                        rows={4}
                        name="comments"
                        label="Message"
                        value={massNotificationData.comments}
                        onChange={handleInputChange}
                        variant="outlined"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#f9f9f9',
                                borderRadius: '8px'
                            }
                        }}
                    />

                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={massNotificationFileRef}
                        onChange={handleMassNotificationFileSelect}
                    />
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <SoftButton
                            variant="contained"
                            color="info"
                            onClick={() => massNotificationFileRef.current.click()}
                            startIcon={<AttachFileIcon />}
                            sx={{
                                backgroundColor: '#3498db',
                                '&:hover': { backgroundColor: '#2980b9' }
                            }}
                        >
                            Attach Image
                        </SoftButton>
                        {massNotificationData.mentorFile && (
                            <Typography variant="body2" color="textSecondary">
                                {massNotificationData.mentorFile.name}
                            </Typography>
                        )}
                    </Box>

                    {massNotificationData.mentorFile && (
                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                            <img
                                src={URL.createObjectURL(massNotificationData.mentorFile)}
                                alt="Preview"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '200px',
                                    objectFit: 'contain',
                                    borderRadius: '8px'
                                }}
                            />
                        </Box>
                    )}

                    <SoftButton
                        fullWidth
                        variant="contained"
                        onClick={handleSendMassNotification}
                        disabled={
                            !massNotificationData.grade || 
                            !massNotificationData.comments || 
                            sendingMassNotification ||
                            (notificationType === 'student' && massNotificationData.selectedStudents.length === 0)
                        }
                        startIcon={sendingMassNotification ? <CircularProgress size={20} /> : <SendIcon />}
                        sx={{ mt: 2 }}
                    >
                        Send Notification
                    </SoftButton>
                </Box>

                <Snackbar
                    open={!!error || !!successMessage}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity={error ? 'error' : 'success'}
                        sx={{ width: '100%' }}
                    >
                        {error || successMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </DashboardLayout>
    );
};

MassNotification.propTypes = {
    onBack: PropTypes.func
};

MassNotification.defaultProps = {
    onBack: () => { }
};

export default MassNotification;