import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip
} from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
  Image as ImageIcon,
  Send  // Add this line
} from "@mui/icons-material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import Table from "examples/Tables/Table";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { APIData, org } from "authentication/APIData";
import Swal from 'sweetalert2';
import axios from 'axios';
import PropTypes from 'prop-types';
import SoftInput from "components/SoftInput";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import { AttachFile as AttachmentIcon } from '@mui/icons-material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

const ChatMessage = ({ message, isCurrentUser }) => {
  const formatDateTime = (dateTimeArray) => {
    if (!dateTimeArray) return '';
    const [year, month, day, hour, minute] = dateTimeArray;
    const date = new Date(year, month - 1, day, hour, minute);
    return date.toLocaleString();
  };

  return (
    <SoftBox
      display="flex"
      justifyContent={isCurrentUser ? "flex-end" : "flex-start"}
      mb={2}
    >
      <SoftBox
        sx={{
          maxWidth: "70%",
          p: 2,
          borderRadius: 2,
          bgcolor: isCurrentUser ? "info.main" : "grey.200",
          color: isCurrentUser ? "white" : "text.primary",
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            width: 0,
            height: 0,
            border: "8px solid transparent",
            borderTopColor: isCurrentUser ? "info.main" : "grey.200",
            top: 0,
            [isCurrentUser ? "right" : "left"]: -8,
          }
        }}
      >
        <SoftTypography variant="body2">{message.comments}</SoftTypography>
        <SoftTypography variant="caption" sx={{ opacity: 0.8, display: "block", mt: 1 }}>
          {formatDateTime(message.updatedDateTime)}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.shape({
    comments: PropTypes.string.isRequired,
    updatedDateTime: PropTypes.arrayOf(PropTypes.number).isRequired,
    updatedBy: PropTypes.string.isRequired
  }).isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
};

const ImageGallery = ({ images, title }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <SoftBox>
      <SoftTypography variant="h6" mb={2}>{title}</SoftTypography>
      {images.length === 0 ? (
        <SoftTypography variant="body2" color="text.secondary">
          No images available
        </SoftTypography>
      ) : (
        <SoftBox
          display="flex"
          flexWrap="wrap"
          gap={2}
          justifyContent="center"
        >
          {images.map((imageUrl, index) => (
            <SoftBox
              key={index}
              sx={{
                width: 100,
                height: 100,
                cursor: 'pointer',
                border: '1px solid',
                borderColor: 'grey.300',
                borderRadius: 2,
                overflow: 'hidden'
              }}
              onClick={() => setSelectedImage(imageUrl)}
            >
              <img
                src={imageUrl}
                alt={`${title} ${index + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </SoftBox>
          ))}

          <Dialog
            open={!!selectedImage}
            onClose={() => setSelectedImage(null)}
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={selectedImage}
                  alt="Full size"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '70vh',
                    objectFit: 'contain'
                  }}
                />
              </SoftBox>
              <SoftButton
                variant="text"
                color="secondary"
                onClick={() => setSelectedImage(null)}
                sx={{ position: 'absolute', top: 8, right: 8 }}
              >
                <Close />
              </SoftButton>
            </DialogContent>
          </Dialog>
        </SoftBox>
      )}
    </SoftBox>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired
};

function StudentUpdate() {
  const [userdata, setUserData] = useState(null);
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [conversationData, setConversationData] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [mentorData, setMentor] = useState({ name: 'Loading...' });
  const [replyMessage, setReplyMessage] = useState('');
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files);

    // Check maximum number of images (5)
    const validImageFiles = files.filter(file => {
      // Check file size (less than 2MB)
      if (file.size > 2 * 1024 * 1024) {
        Swal.fire({
          icon: "error",
          title: "Image Too Large",
          text: `${file.name} exceeds 2MB limit. Please choose a smaller image.`
        });
        return false;
      }
      return true;
    });

    // Limit to 5 images
    const finalImageFiles = validImageFiles.slice(0, 5 - selectedImages.length);

    if (finalImageFiles.length > 0) {
      setSelectedImages(prev => [...prev, ...finalImageFiles]);
    }
  };

  const removeImage = (indexToRemove) => {
    setSelectedImages(prev => prev.filter((_, index) => index !== indexToRemove));
  };

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);

  const formatDateTime = (dateTimeArray) => {
    if (!dateTimeArray) return '';
    const [year, month, day, hour, minute] = dateTimeArray;
    const date = new Date(year, month - 1, day, hour, minute);
    return date.toLocaleString();
  };

  useEffect(() => {
    const userData_Local = localStorage.getItem("sessiondetails");
    if (userData_Local) {
      try {
        const parsedUserData = JSON.parse(userData_Local);
        setUserData(parsedUserData);
      } catch (error) {
        console.error("Error parsing session details:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (userdata?.email) {
      fetchStudentData();
    }
  }, [userdata]);

  useEffect(() => {
    if (student && student.mentor) {
      fetchConversationData();
    }
  }, [student, fromDate, toDate]);

  useEffect(() => {
    const fetchMentor = async () => {
      if (student?.mentor) {
        try {
          const response = await fetch(`${APIData.api}employee/detail?emailId=${student.mentor}`, {
            headers: APIData.headers
          });
          const data = await response.json();
          setMentor(data);

        } catch (error) {
          console.error("Error fetching mentor details:", error);
        }
      }
    };
    fetchMentor();
  }, [student?.mentor]);

  const fetchStudentData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${APIData.api}studentadmissiondetails/email?email=${userdata.email}`,
        { headers: APIData.headers }
      );

      if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        setStudent(response.data[0]);

      } else {
        Swal.fire({
          icon: "warning",
          title: "No Data",
          text: "No student details found for this email.",
        });
      }
    } catch (error) {
      console.error("Detailed Error:", error.response ? error.response.data : error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Unable to fetch student details",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchConversationData = async () => {
    if (!student?.mentor) return;

    try {
      const response = await fetch(
        `${APIData.api}student/updates/mentor-date-range?mentorId=${student.mentor}&org=${org}&fromDate=${fromDate}&toDate=${toDate}`,
        { headers: APIData.headers }
      );
      const data = await response.json();

      // Filter conversations where owner is "STUDENT"
      const studentConversations = data.filter(conversation => conversation.owner === "STUDENT");

      setConversationData(studentConversations);
      setSelectedConversation(null);

    } catch (error) {
      console.error("Error fetching conversation data:", error);
    }
  };

  const validateComment = (comment) => {
    const trimmedComment = comment.trim();
    return trimmedComment.length === 0 || trimmedComment.length > 15;
  };

  const handleSendReply = async () => {
    // Prevent sending if both message and images are empty
    if (!validateComment(replyMessage) || selectedImages.length === 0) return;

    setIsUploading(true);

    try {
      let imageUploadResponse = null;
      // First, upload images if any
      if (selectedImages.length > 0) {
        const formData = new FormData();
        formData.append('id', selectedConversation.id);
        selectedImages.forEach((file) => {
          formData.append('studentFile', file);
        });
        formData.append('mentorFile', '');

        imageUploadResponse = await axios.post(
          `${APIData.api}student/updates/${selectedConversation.id}/upload-images`,
          formData,
          {
            headers: {
              ...APIData.headers,
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      }

      // Then send the message if there's content
      if (replyMessage.trim()) {
        const replyPayload = {
          id: selectedConversation.id,
          studentName: selectedConversation.studentName,
          studentId: selectedConversation.studentId,
          studentEmail: selectedConversation.studentEmail,
          mentorId: selectedConversation.mentorId,
          mentorName: selectedConversation.mentorName,
          queries: [
            ...selectedConversation.queries,
            {
              updatedBy: selectedConversation.studentId,
              updatedDateTime: new Date().toISOString().slice(0, 19).replace('T', ' ').split(/[-: ]/).map(Number),
              comments: replyMessage
            }
          ],
          grade: selectedConversation.grade,
          studentImages: selectedConversation.studentImages || [],
          mentorImages: selectedConversation.mentorImages || [],
          month: selectedConversation.month,
          year: selectedConversation.year,
          org: selectedConversation.org,
          owner: selectedConversation.owner,
          createdBy: userdata.email,
          createdDateTime: selectedConversation.createdDateTime,
          viewStatus: "UNREAD"
        };

        await axios.post(
          `${APIData.api}student/updates`,
          replyPayload,
          { headers: APIData.headers }
        );
      }

      // Refresh conversation data
      fetchConversationData();
      setReplyMessage('');
      setSelectedImages([]);

      Swal.fire({
        icon: "success",
        title: "Reply Sent",
        text: "Your message and/or images have been sent successfully.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000
      });

    } catch (error) {
      console.error("Error sending reply:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to send reply. Please try again.",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const LoadingOverlay = () => (
    <SoftBox
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 1000
      }}
    >
      <CircularProgress color="info" />
    </SoftBox>
  );

  const columns = [
    { name: "Mentor", align: "left" },
    { name: "Student", align: "left" },
    { name: "Raised Date", align: "left" },
    { name: "Action", align: "center" }
  ];

  const rows = conversationData
    .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
    .map((conversation) => ({
      Mentor: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox mr={2}>
            <SoftAvatar
              src={`https://ui-avatars.com/api/?name=${encodeURIComponent(conversation.mentorName)}&background=random`}
              alt={conversation.mentorName}
              size="sm"
              variant="rounded"
            />
          </SoftBox>
          <SoftTypography variant="button" fontWeight="medium">
            {conversation.mentorName}
          </SoftTypography>
        </SoftBox>
      ),
      Student: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox mr={2}>
            <SoftAvatar
              src={`https://ui-avatars.com/api/?name=${encodeURIComponent(conversation.studentName)}&background=random`}
              alt={conversation.studentName}
              size="sm"
              variant="rounded"
            />
          </SoftBox>
          <SoftTypography variant="button" fontWeight="medium">
            {conversation.studentName}
          </SoftTypography>
        </SoftBox>
      ),
      "Raised Date": formatDateTime(conversation.createdDateTime),
      Action: (
        <SoftButton
          variant="text"
          color="info"
          onClick={() => setSelectedConversation(conversation)}
        >
          View
        </SoftButton>
      ),
    }));

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <h3>Loading student details...</h3>
        </div>
      </DashboardLayout>
    );
  }

  if (!student) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <h3>No student details available.</h3>
        </div>
      </DashboardLayout>
    );
  }

  const handleNewClick = () => {
    navigate("/student-new-chat");
  };

  const handleNotificationClick = () => {
    navigate("/notification");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <CardContent>
          <SoftBox mb={3}>
            <SoftTypography variant="h6"></SoftTypography>
          </SoftBox>

          <SoftBox display="flex" alignItems="center" justifyContent="space-between" mb={3}>
            <SoftBox display="flex" alignItems="center" gap={3}>
              <SoftBox display="flex" alignItems="center" gap={1}>
                <SoftTypography variant="h6">Mentor:</SoftTypography>
                <SoftTypography variant="body1">
                  {mentorData ? mentorData.name : 'Loading...'}
                </SoftTypography>
              </SoftBox>

              <SoftBox display="flex" alignItems="center" gap={2}>
                <SoftTypography variant="h6">From:</SoftTypography>
                <SoftInput
                  type="date"
                  required
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  sx={{ width: "150px" }}
                />
              </SoftBox>

              <SoftBox display="flex" alignItems="center" gap={2}>
                <SoftTypography variant="h6">To:</SoftTypography>
                <SoftInput
                  type="date"
                  required
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  sx={{ width: "150px", mr: 3 }}
                />
              </SoftBox>
            </SoftBox>

            <SoftBox display="flex" alignItems="center">
              <Tooltip title="Add new asset group" arrow>
                <SoftButton variant="gradient" color="info" size="small" onClick={handleNewClick}>
                  New
                </SoftButton>
              </Tooltip>
              <Tooltip title="Daily Notifications" arrow>
                <IconButton
                  onClick={handleNotificationClick}
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: 'primary.main'
                    },
                    ml: 2
                  }}
                >
                  <NotificationsNoneOutlinedIcon />
                  <SoftBox
                    position="absolute"
                    top={0}
                    right={0}
                    bgcolor="error.main"
                    color="white"
                    borderRadius="50%"
                    width={16}
                    height={16}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize={10}
                  >
                  </SoftBox>
                </IconButton>
              </Tooltip>
            </SoftBox>
          </SoftBox>

          <SoftBox display="flex" gap={3}>
            {/* Left side: Table */}
            <SoftBox flex={1}>
              {conversationData.length > 0 ? (
                <SoftBox>
                  <Table columns={columns} rows={rows} />
                  <SoftBox display="flex" justifyContent="flex-end" p={2}>
                    <SoftBox display="flex" gap={1} alignItems="center">
                      <SoftTypography variant="caption" color="secondary">
                        {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, conversationData.length)} of {conversationData.length}
                      </SoftTypography>
                      <SoftButton
                        variant="text"
                        color="info"
                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                        disabled={page === 0}
                        iconOnly
                      >
                        <KeyboardArrowLeft />
                      </SoftButton>
                      <SoftButton
                        variant="text"
                        color="info"
                        onClick={() => setPage(prev => Math.min(Math.ceil(conversationData.length / itemsPerPage) - 1, prev + 1))}
                        disabled={page >= Math.ceil(conversationData.length / itemsPerPage) - 1}
                        iconOnly
                      >
                        <KeyboardArrowRight />
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              ) : (
                <SoftTypography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                  py={3}
                >
                  No conversation data available for this mentor.
                </SoftTypography>
              )}
            </SoftBox>

            {/* Right side: Chat */}
            {selectedConversation && (
              <SoftBox
                flex={1}
                sx={{
                  borderLeft: 1,
                  borderColor: "grey.300",
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <SoftBox p={3} bgcolor="grey.100" borderRadius="borderRadius">
                  {/* Conversation Header */}
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <SoftBox display="flex" alignItems="center" gap={2}>
                      <SoftAvatar
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(selectedConversation.mentorName)}&background=random`}
                        alt={selectedConversation.mentorName}
                        size="sm"
                      />
                      <SoftBox>
                        <SoftTypography variant="h6">
                          {selectedConversation.mentorName}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="text.secondary">
                          Mentor
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <SoftButton
                      variant="text"
                      color="secondary"
                      onClick={() => setSelectedConversation(null)}
                      iconOnly
                    >
                      <Close />
                    </SoftButton>
                  </SoftBox>

                  {/* Chat Messages */}
                  <SoftBox
                    sx={{
                      height: "300px",
                      overflowY: "auto",
                      p: 2,
                      bgcolor: "white",
                      borderRadius: 1
                    }}
                  >
                    {selectedConversation.queries.map((query, index) => (
                      <ChatMessage
                        key={index}
                        message={query}
                        isCurrentUser={query.updatedBy === selectedConversation.studentId}
                      />
                    ))}

                  </SoftBox>
                  <SoftBox
                    p={2}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    sx={{
                      borderTop: 1,
                      borderColor: "grey.300"
                    }}
                  >
                    <SoftInput
                      multiline
                      rows={2}
                      placeholder="Type your reply..."
                      value={replyMessage}
                      onChange={(e) => setReplyMessage(e.target.value)}
                      sx={{ flex: 1 }}
                    />
                    <SoftButton
                      variant="text"
                      color="info"
                      component="label"
                      iconOnly
                      sx={{ mr: 1 }}
                    >
                      <AttachmentIcon />
                      <input
                        type="file"
                        hidden
                        multiple
                        accept="image/*"
                        onChange={handleImageSelect}
                        disabled={selectedImages.length >= 5}
                      />
                    </SoftButton>
                    <SoftButton
                      variant="contained"
                      color="info"
                      onClick={handleSendReply}
                      disabled={(!replyMessage.trim() && selectedImages.length === 0) || isUploading}
                      iconOnly
                    >
                      {isUploading ? <CircularProgress size={24} /> : <Send />}
                    </SoftButton>
                  </SoftBox>

                  {selectedImages.length > 0 && (
                    <SoftBox mt={1} display="flex" gap={1}>
                      {selectedImages.map((image, index) => (
                        <SoftBox
                          key={index}
                          sx={{
                            position: 'relative',
                            width: 50,
                            height: 50,
                            borderRadius: 1,
                            overflow: 'hidden'
                          }}
                        >
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Selected ${index}`}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                          />
                          <SoftButton
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => removeImage(index)}
                            sx={{
                              position: 'absolute',
                              top: -5,
                              right: -5,
                              minWidth: 20,
                              width: 20,
                              height: 20,
                              p: 0,
                              borderRadius: '50%'
                            }}
                          >
                            <Close fontSize="small" />
                          </SoftButton>
                        </SoftBox>
                      ))}
                    </SoftBox>
                  )}

                  {/* Images Section */}
                  <SoftBox mt={2}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      <SoftTypography variant="h6">Media</SoftTypography>
                      <SoftButton
                        variant="text"
                        color="info"
                        startIcon={<ImageIcon />}
                      >
                        View All
                      </SoftButton>
                    </SoftBox>

                    <SoftBox display="flex" gap={2}>
                      <SoftBox flex={1}>
                        <ImageGallery
                          images={selectedConversation.studentImages || []}
                          title="Student Images"
                        />
                      </SoftBox>
                      <SoftBox flex={1}>
                        <ImageGallery
                          images={selectedConversation.mentorImages || []}
                          title="Mentor Images"
                        />
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox mt={2}>
                    <SoftTypography variant="caption" color="text.secondary">
                      Created: {formatDateTime(selectedConversation.createdDateTime)}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default StudentUpdate;