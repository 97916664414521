import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData, org } from '../authentication/APIData';
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Name = ({ name, email }) => (
  <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
    <SoftBox mr={2}>
      <SoftAvatar
        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
        alt={name}
        size="sm"
        variant="rounded"
      />
    </SoftBox>
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="button" fontWeight="medium">
        {name}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {email}
      </SoftTypography>
    </SoftBox>
  </SoftBox>
);

Name.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

const Organization = ({ salesType, orgName }) => (
  <SoftBox display="flex" flexDirection="column">
    <SoftTypography variant="caption" fontWeight="medium" color="text">
      {orgName}
    </SoftTypography>
    {/* <SoftTypography variant="caption" color="secondary">
      {orgName}
    </SoftTypography> */}
  </SoftBox>
);

Organization.propTypes = {
  salesType: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
};

const CustomerSalesOnboard = () => {
  const [salesData, setSalesData] = useState([]);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [userDepartment, setUserDepartment] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(parseInt(accessLevel)); 
    const Department = localStorage.getItem('Depart Details');
    setUserDepartment(Department);
  }, []);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      let salesType = "";
      if (isSuperAdmin || userDepartment === "SALES") {
        switch (tabValue) {
          case 1:
            salesType = "LEAD";
            break;
          case 2:
            salesType = "PROSPECT";
            break;
          case 3:
            salesType = "OPPURTUNITY";
            break;
          case 0:
          default:
            salesType = "All";
        }
      } else {
        salesType = "LEAD";
      }

      const baseUrl = `${APIData.api}customer-sales/`;
      const url = salesType === 'All'
        ? `${baseUrl}?org=${org}`
        : `${baseUrl}salestype?salesType=${salesType}&org=${org}`;

      try {
        const response = await fetch(url, { headers: APIData.headers });
        const data = await response.json();
        setSalesData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [tabValue, isSuperAdmin, userDepartment]);

  const shouldShowActions = () => {
    if (permission === 1100 || permission === 1000) {
      return false;
    }
    return isSuperAdmin || userDepartment === "SALES";
  };

  const filteredSales = salesData.filter(sale =>
    sale.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sale.customerEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sale.org_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sale.salesType.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sale.customerMobileNum.includes(searchTerm) ||
    sale.salesYear.toString().includes(searchTerm)
  );

  const paginatedSales = filteredSales.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const columns = [
    { name: "Name", align: "left" },
    { name: "Organization", align: "left" },
    { name: "Sales_Type", align: "center" },
    { name: "Contact", align: "center" },
    { name: "Sales_Year", align: "center" },
    ...(shouldShowActions() ? [{ name: "Action", align: "left" }] : []),
  ];

  const rows = paginatedSales.map((sale) => ({
    Name: <Name name={sale.customerName} email={sale.customerEmail} />,
    Organization: <Organization orgName={sale.org_name} salesType={sale.salesType} />,
    Sales_Type: (
      <SoftBadge
        variant="gradient"
        badgeContent={sale.salesType.toLowerCase()}
        color={sale.salesType === "OPPURTUNITY" ? "success" : "secondary"}
        size="xs"
        container
      />
    ),
    Contact: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {sale.customerMobileNum}
      </SoftTypography>
    ),
    Sales_Year: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {sale.salesYear}
      </SoftTypography>
    ),
    ...(shouldShowActions() ? {
      Action: (
        <>
          <Link to={`/edit-customer-sales/${sale.customerEmail}`}>
            <SoftTypography
              component="a"
              variant="button"
              color="secondary"
              fontWeight="medium"
            >
              Edit
            </SoftTypography>
          </Link>
          {sale.salesType === "OPPURTUNITY" && (
            <Link to="/orgmdmonboarding" state={{ salesData: sale }}>
              <SoftTypography
                component="a"
                variant="button"
                color="info"
                fontWeight="medium"
                sx={{ marginLeft: "10px" }}
              >
                OnBoard
              </SoftTypography>
            </Link>
          )}
        </>
      )
    } : {}),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            {shouldShowActions() && (
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={6} lg={16} sx={{ ml: "0" }}>
                  <AppBar position="static">
                    <Tabs
                      orientation={tabsOrientation}
                      value={tabValue}
                      onChange={handleSetTabValue}
                      sx={{ background: "transparent" }}
                    >
                      <Tab
                        label="All"
                        sx={{
                          color: tabValue === 0 ? "info.main" : "text.secondary",
                          fontWeight: tabValue === 0 ? "bold" : "normal",
                          borderBottom: tabValue === 0 ? "2px solid" : "none",
                          borderColor: "info.main",
                        }}
                      />
                      <Tab
                        label="Lead"
                        sx={{
                          color: tabValue === 1 ? "info.main" : "text.secondary",
                          fontWeight: tabValue === 1 ? "bold" : "normal",
                          borderBottom: tabValue === 1 ? "2px solid" : "none",
                          borderColor: "info.main",
                        }}
                      />
                      <Tab
                        label="Prospect"
                        sx={{
                          color: tabValue === 2 ? "info.main" : "text.secondary",
                          fontWeight: tabValue === 2 ? "bold" : "normal",
                          borderBottom: tabValue === 2 ? "2px solid" : "none",
                          borderColor: "info.main",
                        }}
                      />
                      <Tab
                        label="Opportunity"
                        sx={{
                          color: tabValue === 3 ? "info.main" : "text.secondary",
                          fontWeight: tabValue === 3 ? "bold" : "normal",
                          borderBottom: tabValue === 3 ? "2px solid" : "none",
                          borderColor: "info.main",
                        }}
                      />
                    </Tabs>
                  </AppBar>
                </Grid>
              </Grid>
            )}
          </Card>
          <SoftBox mt={2}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Customer Sales</SoftTypography>
              <SoftBox display="flex" alignItems="center" gap={2}>
                <SoftBox>
                  <SoftInput
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                  />
                </SoftBox>
                {shouldShowActions() && (
                  <Link to='/salesonboard'>
                    <SoftButton variant="gradient" color="info" size="small">+ New Sales</SoftButton>
                  </Link>
                )}
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
              <SoftBox
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                p={3}
              >
                <SoftBox display="flex" gap={1} alignItems="center">
                  <SoftTypography variant="caption" color="secondary">
                    {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredSales.length)} of {filteredSales.length}
                  </SoftTypography>
                  <SoftButton
                    variant="text"
                    color="info"
                    onClick={() => setPage(prev => Math.max(0, prev - 1))}
                    disabled={page === 0}
                    iconOnly
                  >
                    <KeyboardArrowLeft />
                  </SoftButton>
                  <SoftButton
                    variant="text"
                    color="info"
                    onClick={() => setPage(prev => Math.min(Math.ceil(filteredSales.length / itemsPerPage) - 1, prev + 1))}
                    disabled={page >= Math.ceil(filteredSales.length / itemsPerPage) - 1}
                    iconOnly
                  >
                    <KeyboardArrowRight />
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default CustomerSalesOnboard;