import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Grid, Card, Backdrop, CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { APIData, org } from '../authentication/APIData';
import * as AiIcons from "react-icons/ai";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import ChatBot from "ChatBot/ChatBot";

const MAX_CHAR_LENGTH = 45;
const MAX_ADDR_LENGTH = 255;
const MIN_DESC_LENGTH = 10;
const MAX_DESC_LENGTH = 1000;
const MAX_CONTINUOUS_NUMBERS = 3;
const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails")) || "";
const job_created_by = sessiondetails.user;

const AddJob = () => {
    const [formData, setFormData] = useState({
        branch: "",
        description: "",
        createdBy: job_created_by,
        updatedBy: job_created_by,
        id: 0,
        image_url: "",
        job_dept: "",
        job_designation: "",
        org: org,
        job_tenure: "",
        job_type: "",
        job_opening_type: "",
        job_id: "",
        location: "",
        role: "",
        roles_and_resp: "",
        salary: "",
        valid: "",
    });

    const [selectedImage, setSelectedImage] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [touchedFields, setTouchedFields] = useState({});

    const requiredFieldsOrder = [
        'job_dept',
        'job_designation',
        'role',
        'job_tenure',
        'job_type',
        'job_opening_type',
        'location',
        'salary',
        'roles_and_resp',
        'description'
    ];

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const departmentsUrl = APIData.api + `org-designation/all-dept?org=${org}`;
                const departmentsResponse = await axios.get(departmentsUrl, { headers: APIData.headers });
                setDepartments(departmentsResponse.data);
            } catch (error) {
                toast.error("Error fetching departments");
            }
        };
        fetchDepartments();
    }, []);

    useEffect(() => {
        if (formData.job_dept) {
            const fetchDesignations = async () => {
                try {
                    const designationsUrl = APIData.api + `org-designation/all-designation?department=${formData.job_dept}&org=${org}`;
                    const designationsResponse = await axios.get(designationsUrl, { headers: APIData.headers });
                    setDesignations(designationsResponse.data);
                } catch (error) {
                    toast.error("Error fetching designations");
                }
            };
            fetchDesignations();
        }
    }, [formData.job_dept]);

    useEffect(() => {
        if (formData.job_dept && formData.job_designation) {
            const fetchRoles = async () => {
                try {
                    const rolesUrl = APIData.api + `org-designation/all-roles?department=${formData.job_dept}&designation=${formData.job_designation}&org=${org}`;
                    const rolesResponse = await axios.get(rolesUrl, { headers: APIData.headers });
                    setRoles(rolesResponse.data);
                } catch (error) {
                    toast.error("Error fetching roles");
                }
            };
            fetchRoles();
        }
    }, [formData.job_designation]);

    const handleFieldTouch = (fieldName) => {
        setTouchedFields(prev => {
            const newTouchedFields = { ...prev };
            const currentIndex = requiredFieldsOrder.indexOf(fieldName);

            for (let i = 0; i <= currentIndex; i++) {
                newTouchedFields[requiredFieldsOrder[i]] = true;
            }

            return newTouchedFields;
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                Swal.fire({
                    icon: "error",
                    title: "Size limit Exceeded!",
                    text: "File size should be less than 1MB",
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                setSelectedImage(base64String);
                const base64Data = base64String.split(',')[1];
                setFormData(prev => ({
                    ...prev,
                    image_url: base64Data
                }));
            };
            reader.onerror = () => {
                toast.error("Error reading file");
            };
            reader.readAsDataURL(file);
        }
    };

    const hasContinuousNumbers = (text) => {
        const numberRegex = /\d{4,}/g;
        return numberRegex.test(text);
    };

    useEffect(() => {
        validateForm();
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        handleFieldTouch(name);

        if (name === "location" && value.length > MAX_ADDR_LENGTH) {
            setErrors(prev => ({
                ...prev,
                [name]: `Cannot exceed ${MAX_ADDR_LENGTH} characters`
            }));
            return;
        }

        if (name === "salary") {
            if (!/^\d*$/.test(value)) {
                setErrors(prev => ({
                    ...prev,
                    [name]: "Only numbers are allowed"
                }));
                return;
            }
            if (value.length > MAX_CHAR_LENGTH) {
                setErrors(prev => ({
                    ...prev,
                    [name]: `Cannot exceed ${MAX_CHAR_LENGTH} characters`
                }));
                return;
            }
        }

        if (name === "description") {
            if (value.length < MIN_DESC_LENGTH || value.length > MAX_DESC_LENGTH) {
                setErrors(prev => ({
                    ...prev,
                    [name]: `Description must be between ${MIN_DESC_LENGTH} and ${MAX_DESC_LENGTH} characters`
                }));
            }
        }

        if ((name === "roles_and_resp" || name === "description") && hasContinuousNumbers(value)) {
            setErrors(prev => ({
                ...prev,
                [name]: "Cannot contain more than 3 continuous numbers"
            }));
            return;
        }

        setFormData(prev => ({
            ...prev,
            [name]: value,
            ...(name === "role" && { job_id: value }),
        }));

        if (
            (name === "location" && value.length <= MAX_ADDR_LENGTH) ||
            (name === "salary" && /^\d*$/.test(value) && value.length <= MAX_CHAR_LENGTH) ||
            (name === "description" && value.length >= MIN_DESC_LENGTH && value.length <= MAX_DESC_LENGTH && !hasContinuousNumbers(value)) ||
            (name === "roles_and_resp" && !hasContinuousNumbers(value))) {
            setErrors(prev => ({ ...prev, [name]: "" }));
        }
    };

    const validateForm = () => {
        const newErrors = {};

        requiredFieldsOrder.forEach(field => {
            if (touchedFields[field] && !formData[field]) {
                newErrors[field] = `${field.replace(/_/g, ' ')} is required`;
            }
        });

        // Additional validation rules
        if (touchedFields.location && formData.location && formData.location.length > MAX_ADDR_LENGTH) {
            newErrors.location = `Cannot exceed ${MAX_ADDR_LENGTH} characters`;
        }

        if (touchedFields.salary && formData.salary) {
            if (!/^\d+$/.test(formData.salary)) {
                newErrors.salary = "Salary must be a numeric value";
            }
            if (formData.salary.length > MAX_CHAR_LENGTH) {
                newErrors.salary = `Cannot exceed ${MAX_CHAR_LENGTH} characters`;
            }
        }

        if (touchedFields.description && formData.description) {
            if (formData.description.length < MIN_DESC_LENGTH) {
                newErrors.description = `Description must be at least ${MIN_DESC_LENGTH} characters`;
            } else if (formData.description.length > MAX_DESC_LENGTH) {
                newErrors.description = `Description cannot exceed ${MAX_DESC_LENGTH} characters`;
            }
        }

        if (touchedFields.roles_and_resp && formData.roles_and_resp && hasContinuousNumbers(formData.roles_and_resp)) {
            newErrors.roles_and_resp = "Cannot contain more than 3 continuous numbers";
        }

        if (touchedFields.description && formData.description && hasContinuousNumbers(formData.description)) {
            newErrors.description = "Cannot contain more than 3 continuous numbers";
        }

        setErrors(newErrors);
        setIsFormValid(Object.keys(newErrors).length === 0 && requiredFieldsOrder.every(field => formData[field]));
        return Object.keys(newErrors).length === 0;
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        handleFieldTouch(name);

        const value = selectedOption ? selectedOption.value : '';
        setFormData(prev => ({
            ...prev,
            [name]: value,
            ...(name === "role" && { job_id: value })
        }));
        setErrors(prev => ({ ...prev, [name]: "" }));
    };

    const renderLocationInput = () => (
        <SoftBox mb={1}>
            <SoftTypography variant="h6">Job Location</SoftTypography>
            <SoftInput
                placeholder="Job Location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                error={!!errors.location}
                required
            />
            <SoftTypography variant="caption" color={errors.location ? "error" : "dark"}>
                {errors.location || `${formData.location.length}/${MAX_ADDR_LENGTH} characters`}
            </SoftTypography>
        </SoftBox>
    );

    const renderRolesAndResponsibilities = () => (
        <SoftBox mb={1}>
            <SoftTypography variant="h6">Roles and Responsibilities</SoftTypography>
            <SoftInput
                placeholder="Roles and Responsibilities"
                name="roles_and_resp"
                value={formData.roles_and_resp}
                onChange={handleChange}
                multiline
                rows={4}
                error={!!errors.roles_and_resp}
            />
            <SoftTypography variant="caption" color={errors.roles_and_resp ? "error" : "dark"}>
                {errors.roles_and_resp || "Cannot contain more than 3 continuous numbers"}
            </SoftTypography>
        </SoftBox>
    );


    const renderSalaryInput = () => (
        <SoftBox mb={1}>
            <SoftTypography variant="h6">Salary/Month</SoftTypography>
            <SoftInput
                placeholder="Salary/Month"
                name="salary"
                value={formData.salary}
                onChange={handleChange}
                error={!!errors.salary}
            />
            <SoftTypography variant="caption" color={errors.salary ? "error" : "dark"}>
                {errors.salary || `${formData.salary.length}/${MAX_CHAR_LENGTH} characters`}
            </SoftTypography>
        </SoftBox>
    );

    const renderDescriptionInput = () => (
        <SoftBox mb={1}>
            <SoftTypography variant="h6">Description</SoftTypography>
            <SoftInput
                placeholder="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
                error={!!errors.description}
            />
            <SoftTypography variant="caption" color={errors.description ? "error" : "dark"}>
                {errors.description || `${formData.description.length} characters (${MIN_DESC_LENGTH}-${MAX_DESC_LENGTH} required). Cannot contain more than 3 continuous numbers`}
            </SoftTypography>
        </SoftBox>
    );

    const formatDataForSubmission = () => {
        return {
            branch: formData.location,
            createdBy: formData.createdBy,
            description: formData.description,
            id: 0,
            image_url: formData.image_url || "",
            job_dept: formData.job_dept,
            job_designation: formData.job_designation,
            job_id: "",
            job_opening_type: formData.job_opening_type,
            job_tenure: formData.job_tenure,
            job_type: formData.job_type,
            location: formData.location,
            org: org,
            role: formData.role,
            roles_and_resp: formData.roles_and_resp,
            salary: formData.salary.toString(),
            updatedBy: formData.updatedBy,
            valid: "YES"
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Mark all fields as touched when submitting
        const allTouched = requiredFieldsOrder.reduce((acc, field) => {
            acc[field] = true;
            return acc;
        }, {});
        setTouchedFields(allTouched);

        if (!validateForm()) {
            toast.error("Please fix all form errors before submitting.");
            return;
        }

        setLoading(true);

        try {
            const formattedData = formatDataForSubmission();
            const response = await axios.post(
                `${APIData.api}jobs/`,
                formattedData,
                {
                    headers: {
                        ...APIData.headers,
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response.status === 200 || response.status === 201) {
                toast.success("Job created successfully");
                Swal.fire({
                    icon: "success",
                    title: "Job created successfully",
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    navigate("/jobEditButtons");
                }, 1500);

                setFormData({
                    branch: "",
                    description: "",
                    createdBy: job_created_by,
                    updatedBy: job_created_by,
                    id: 0,
                    image_url: "",
                    job_dept: "",
                    job_designation: "",
                    org: org,
                    job_tenure: "",
                    job_type: "",
                    job_opening_type: "",
                    job_id: "",
                    location: "",
                    role: "",
                    roles_and_resp: "",
                    salary: "",
                    valid: "",
                });
                setSelectedImage(null);
            }
        } catch (error) {
            console.error('Error details:', {
                response: error.response?.data,
                status: error.response?.status,
                headers: error.response?.headers
            });

            const errorMessage = error.response?.data?.message || "Error creating job";
            Swal.fire({
                icon: "error",
                title: "Oops!",
                text: errorMessage,
                footer: 'Please check all required fields and try again'
            });
        } finally {
            setLoading(false);
        }
    };

    const selectOptions = {
        job_tenure: ["3 Months", "6 Months", "9 Months", "12 Months"],
        job_type: ["PERMANENT", "CONTRACTOR"],
        job_opening_type: ["INTERNAL", "EXTERNAL"],

    };

    const departmentOptions = departments.map(dept => ({
        value: dept,
        label: dept
    }));

    const designationOptions = designations.map(designation => ({
        value: designation,
        label: designation
    }));

    const roleOptions = roles.map(role => ({
        value: role,
        label: role
    }));

    const selectOptionsFormatted = {
        job_tenure: selectOptions.job_tenure.map(option => ({
            value: option,
            label: option
        })),
        job_type: selectOptions.job_type.map(option => ({
            value: option,
            label: option
        })),
        job_opening_type: selectOptions.job_opening_type.map(option => ({
            value: option,
            label: option
        }))
    };

    return (
        <DashboardLayout>
            {/* <DashboardNavbar /> */}
            {/* <Grid
                container
                spacing={0}
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "100%" }}
            >

                <Grid item xs={11} sm={10} md={8} lg={6}> 
                    <Card sx={{ width: "100%", height: "auto" }}> */}
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4">
            <Grid item xs={12} sm={12} md={12} lg={10}>
                    <Card className="w-full">

                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            {/* <Link to="/jobEditButtons">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link> */}
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Create New Job
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={2} pb={3} px={3}>
                            <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    {/* <Grid item xs={12} sm={6}>
                                        <SoftBox mb={2}>
                                            <SoftInput
                                                placeholder="Branch"
                                                name="branch"
                                                value={formData.branch}
                                                onChange={handleChange}
                                                error={!!errors.branch}
                                            />
                                            {errors.branch && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.branch}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid> */}

                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6">Choose Department</SoftTypography>
                                            <CustomSelect
                                                options={departmentOptions}
                                                placeholder="Choose Department"
                                                name="job_dept"
                                                onChange={handleSelectChange}
                                                value={formData.job_dept}
                                                error={!!errors.job_dept}
                                            />

                                            {errors.job_dept && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.job_dept}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6">Choose Designation</SoftTypography>
                                            <CustomSelect
                                                options={designationOptions}
                                                placeholder="Choose Designation"
                                                name="job_designation"
                                                onChange={handleSelectChange}
                                                value={formData.job_designation}
                                                isDisabled={!formData.job_dept}
                                                error={!!errors.job_designation}
                                            />
                                            {errors.job_designation && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.job_designation}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6">Choose Role</SoftTypography>
                                            <CustomSelect
                                                options={roleOptions}
                                                placeholder="Choose Role"
                                                name="role"
                                                onChange={handleSelectChange}
                                                value={formData.role}
                                                isDisabled={!formData.job_designation}
                                                error={!!errors.role}
                                            />

                                            {errors.role && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.role}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    {Object.entries(selectOptionsFormatted).map(([field, options]) => (
                                        <Grid item xs={12} sm={6} key={field}>
                                            <SoftBox mb={1}>
                                                <SoftTypography variant="h6">
                                                    Choose {field.replace(/_/g, " ").toLowerCase()}
                                                </SoftTypography>
                                                <CustomSelect
                                                    options={options}
                                                    placeholder={`Choose ${field.replace(/_/g, " ")
                                                        .toLowerCase()
                                                        .replace(/\b\w/g, char => char.toUpperCase())}`}
                                                    name={field}
                                                    onChange={handleSelectChange}
                                                    value={formData[field]}
                                                    error={!!errors[field]}
                                                />
                                                {errors[field] && (
                                                    <SoftTypography variant="caption" color="error">
                                                        {errors[field]}
                                                    </SoftTypography>
                                                )}
                                            </SoftBox>
                                        </Grid>
                                    ))}


                                    <Grid item xs={12} sm={6}>
                                        {renderLocationInput()}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {renderSalaryInput()}
                                    </Grid>

                                    <Grid item xs={12}>
                                        {renderRolesAndResponsibilities()}
                                    </Grid>

                                    <Grid item xs={12}>
                                        {renderDescriptionInput()}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SoftBox mb={1}>

                                            <SoftTypography variant="h6">Upload Image</SoftTypography>

                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                style={{ display: "none" }}
                                                id="image-upload"
                                            />
                                            <label htmlFor="image-upload">
                                                <SoftButton
                                                    component="span"
                                                    variant="outlined"
                                                    color="dark"
                                                    fullWidth
                                                    size="small"
                                                >
                                                    Upload Image
                                                </SoftButton>
                                            </label>
                                            {selectedImage && (
                                                <SoftBox mt={2} textAlign="center">
                                                    <img
                                                        src={selectedImage}
                                                        alt="Selected"
                                                        style={{
                                                            maxWidth: "100%",
                                                            maxHeight: "200px",
                                                            objectFit: "contain"
                                                        }}
                                                    />
                                                </SoftBox>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mt={1} mb={1}>
                                            <SoftButton
                                                type="submit"
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                disabled={!isFormValid}
                                            >
                                                Create Job
                                            </SoftButton>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ChatBot />
        </DashboardLayout>
    );

};

export default AddJob;