import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Paper
} from "@mui/material";
import { Mail, Person, AccessTime } from "@mui/icons-material";
import axios from "axios";
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { APIData, exitKeyWord, org } from "../../authentication/APIData";
import PageLayout from "examples/LayoutContainers/PageLayout";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";


const TaskCard = ({ id, empEmail, empUserName, createdTime, status }) => {
  const handleDragStart = (e) => {
    const cardData = {
      id,
      empEmail,
      empUserName,
      createdTime,
      status
    };
    e.dataTransfer.setData("application/json", JSON.stringify(cardData));
    e.dataTransfer.effectAllowed = "move";
  };

  return (
    <Card
      draggable
      onDragStart={handleDragStart}
      sx={{
        width: "280px",
        backgroundColor: "#f8f8f8",
        margin: "0.5rem 0",
        padding: "0.8rem",
        borderRadius: "7px",
        border: "1px solid #ccc",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        cursor: "grab",
        '&:hover': {
          backgroundColor: "#f4f4f4"
        }
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Mail sx={{ color: "#666", mr: 1 }} />
          <Typography variant="body2" color="#24aef7">
            {empEmail}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Person sx={{ color: "#666", mr: 1 }} />
          <Typography variant="body2" color="#24aef7">
            {empUserName}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTime sx={{ color: "#666", mr: 1 }} />
          <Typography variant="body2" color="#24aef7">
            {new Date(createdTime).toLocaleString()}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

TaskCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  empEmail: PropTypes.string.isRequired,
  empUserName: PropTypes.string.isRequired,
  createdTime: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

const Column = ({ id, title, cards, onDrop, index }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    try {
      const cardData = JSON.parse(e.dataTransfer.getData("application/json"));
      onDrop(cardData, title, id);
    } catch (error) {
      console.error("Error handling drop:", error);
    }
  };

  return (
    <Paper
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      sx={{
        width:"100%",
        minHeight: 400,
        backgroundColor: "#f4f4f4",
        padding: "1rem",
        margin: "0.5rem",
        borderRadius: "5px",
        border: "1px solid #ccc"
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#24aef7",
          mb: 2,
          textAlign: "center",
          fontWeight: "bold"
        }}
      >
        {title}
      </Typography>
      <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
        {cards?.map((card) => (
          <TaskCard key={card.id} {...card} status={title} />
        ))}
      </Box>
    </Paper>
  );
};

Column.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    empEmail: PropTypes.string.isRequired,
    empUserName: PropTypes.string.isRequired,
    createdTime: PropTypes.string.isRequired
  })).isRequired,
  onDrop: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

const ControlledBoard = () => {
  const [columns, setColumns] = useState([]);
  const [lifeCycleStatus, setLifeCycleStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = APIData.sessiondetails;

  const updateColumnsAfterMove = (cardId, sourceStatus, targetStatus) => {
    setColumns(prevColumns => {
      return prevColumns.map(column => {
        if (column.title === sourceStatus) {
          return {
            ...column,
            cards: column.cards.filter(card => card.id !== cardId)
          };
        }
        if (column.title === targetStatus) {
          const movedCard = prevColumns
            .find(col => col.title === sourceStatus)
            ?.cards
            .find(card => card.id === cardId);

          if (movedCard) {
            return {
              ...column,
              cards: [...column.cards, { ...movedCard, status: targetStatus }]
            };
          }
        }
        return column;
      });
    });
  };

  const createNewStatus = async (card, newStatus) => {
    const url = `${APIData.api}employee/exit?org=${org}`;
    const url1 = `${APIData.api}employee/exit`;


    const oldData = {
      id: card.id,
      empEmail: card.empEmail,
      empUserName: card.empUserName,
      description: card.description || "",
      status: card.status,
      approvedBy: "",
      type: exitKeyWord,
      createdTime: "",
      updatedTime: "",
      org: org,
    };

    const formBody = {
      id: 0,
      empEmail: card.empEmail,
      empUserName: card.empUserName,
      description: "",
      status: newStatus,
      approvedBy: "",
      type: exitKeyWord,
      createdTime: "",
      updatedTime: "",
      org: org,
    };

    try {
      setLoading(true);
      const response = await axios.post(url1, formBody, { headers: APIData.headers });
       await axios.put(url1, oldData, { headers: APIData.headers });

      updateColumnsAfterMove(card.id, card.status, newStatus);

      Swal.fire({
        title: 'Success',
        text: 'Status updated successfully',
        icon: 'success',
        confirmButtonColor: '#24aef7',
      });
      return response.data;
    } catch (error) {
      console.error("Error updating status:", error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to update status: ' + error.message,
        icon: 'error',
        confirmButtonColor: '#24aef7',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleDrop = async (card, newStatus, newColumnId) => {
    const sourceColumn = columns.find(col => col.title === card.status);
    const destinationColumn = columns.find(col => col.id === newColumnId);

    if (!sourceColumn || !destinationColumn) {
      console.error("Could not find source or destination column");
      return;
    }

    if (destinationColumn.id <= sourceColumn.id) {
      Swal.fire({
        title: 'Error',
        text: 'You can\'t move tasks backwards',
        icon: 'error',
        confirmButtonColor: '#24aef7',
      });
      return;
    }

    if (destinationColumn.id - sourceColumn.id > 1) {
      Swal.fire({
        title: 'Error',
        text: 'You can\'t skip stages',
        icon: 'error',
        confirmButtonColor: '#24aef7',
      });
      return;
    }

    try {
      const roles = JSON.parse(sessionStorage.getItem("roles")) || [];
      const authUser = roles.find(role => role.role === sourceColumn.title);

      if (
        user.email.toLowerCase() === authUser?.email_id.toLowerCase() ||
        user.userType === "SUPERADMIN"
      ) {
        await createNewStatus(card, newStatus);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'You don\'t have permission to perform this action',
          icon: 'error',
          confirmButtonColor: '#24aef7',
        });
      }
    } catch (error) {
      console.error("Error in handleDrop:", error);
      Swal.fire({
        title: 'Error',
        text: 'Error processing the move',
        icon: 'error',
        confirmButtonColor: '#24aef7',
      });
    }
  };

  const fetchExitLifeCycleStatusesPending = async (currentStatus) => {
    const url = `${APIData.api}employee/exit/latest-exit-status?type=${exitKeyWord}&org=${org}`;
    try {
      const { data } = await axios.get(url, { headers: APIData.headers });
      
      // Filter data based on the current status
      const filteredData = data.filter(item => item.status === currentStatus);
      
      return filteredData;
    } catch (err) {
      console.error("Error fetching pending statuses:", err);
      Swal.fire({
        title: 'Error',
        text: 'Unable to fetch status data',
        icon: 'error',
        confirmButtonColor: '#24aef7',
      });
      return [];
    }
  };
  
  const fetchExitLifeCycleStatuses = async () => {
    setLoading(true);
    try {
      // Fetch lifecycle statuses
      const { data: statusData } = await axios.get(
        `${APIData.api}employee/life-cycle/type?type=${exitKeyWord}&org=${org}`,
        { headers: APIData.headers }
      );
  
      setLifeCycleStatus(statusData);
  
      // Fetch cards for each status
      const columnsData = await Promise.all(
        statusData.map(async (status) => {
          const cards = await fetchExitLifeCycleStatusesPending(status.statusName);
          return {
            id: status.id,
            title: status.statusName,
            cards
          };
        })
      );
  
      // Sort columns by ID
      const sortedColumns = columnsData.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      setColumns(sortedColumns);
    } catch (error) {
      console.error("Error fetching lifecycle statuses:", error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch lifecycle statuses',
        icon: 'error',
        confirmButtonColor: '#24aef7',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const { data } = await axios.get(
        `${APIData.api}user-roles/?org=${org}`,
        { headers: APIData.headers }
      );
      sessionStorage.setItem("roles", JSON.stringify(data));
    } catch (err) {
      console.error("Error fetching roles:", err);
      Swal.fire({
        title: 'Error',
        text: 'Unable to fetch roles',
        icon: 'error',
        confirmButtonColor: '#24aef7',
      });
    }
  };

  useEffect(() => {
    fetchExitLifeCycleStatuses();
    fetchRoles();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      gap: 2,
      p: 2,
      overflowX: 'auto',
      backgroundColor: '#f4f4f4',
      borderRadius: '5px',
      border: '1px solid #ccc'
    }}>
      {columns.map((column, index) => (
        <Column
          key={column.id}
          {...column}
          onDrop={handleDrop}
          index={index}
        />
      ))}
    </Box>
  );
};

const AdminConsoleForExit = () => {
  return (
    <PageLayout>
      <Box sx={{ p: 3 }}>
        {/* <Link to="/marksData" style={{ textDecoration: 'none' }}>
          <AiIcons.AiOutlineCloseCircle />
        </Link>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            color: "#24aef7",
            fontFamily: "monospace",
            fontSize: "1.5rem",
            mb: 2,
            textDecoration: "underline",
          }}
        >
          Exit Process Console
        </Typography> */}
        <SoftBox p={3} mb={1} display="flex" alignItems="center">
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <AiIcons.AiOutlineCloseCircle />
          </Link>
          <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
            Exit Process Console
          </SoftTypography>
        </SoftBox>
        <ControlledBoard />
      </Box>
    </PageLayout>
  );
};

export default AdminConsoleForExit;



// import React, { useEffect, useState } from "react";
// import { Card, CardContent, Typography, Box, CircularProgress, Paper, Grid, IconButton } from "@mui/material";
// import { Mail, Person, AccessTime } from "@mui/icons-material";
// import axios from "axios";
// import Swal from 'sweetalert2';
// import PropTypes from 'prop-types';
// import { APIData, exitKeyWord, org } from "../../authentication/APIData";
// import PageLayout from "examples/LayoutContainers/PageLayout";
// import SoftTypography from "components/SoftTypography";
// import SoftBox from "components/SoftBox";
// import { Link } from "react-router-dom";
// import * as AiIcons from "react-icons/ai";

// // Fetch lifecycle statuses for tasks in a specific status (e.g., Pending)
// const fetchExitLifeCycleStatusesPending = async (currentStatus) => {
//   const url = `${APIData.api}employee/exit/latest-exit-status?type=${exitKeyWord}&org=${org}`;

//   try {
//     const { data } = await axios.get(url, { headers: APIData.headers });

//     // Filter data based on the current status (e.g., Pending)
//     const filteredData = data.filter(item => item.status === currentStatus);

//     return filteredData;  // Return the filtered data (tasks in the 'currentStatus')
//   } catch (err) {
//     console.error("Error fetching pending statuses:", err);
//     Swal.fire({
//       title: 'Error',
//       text: 'Unable to fetch status data',
//       icon: 'error',
//       confirmButtonColor: '#1A73E8',
//     });
//     return [];  // Return an empty array in case of an error
//   }
// };

// // TaskCard component with modern UI
// const TaskCard = ({ id, empEmail, empUserName, createdTime, status }) => {
//   const handleDragStart = (e) => {
//     const cardData = {
//       id,
//       empEmail,
//       empUserName,
//       createdTime,
//       status
//     };
//     e.dataTransfer.setData("application/json", JSON.stringify(cardData));
//     e.dataTransfer.effectAllowed = "move";
//   };

//   return (
//     <Card
//       draggable
//       onDragStart={handleDragStart}
//       sx={{
//         width: "100%",
//         marginBottom: "1rem",
//         padding: "1rem",
//         borderRadius: "8px",
//         border: "none",
//         boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
//         cursor: "grab",
//         transition: "all 0.3s ease",
//         backgroundColor: "#ffffff",
//         '&:hover': {
//           backgroundColor: "#F0F8FF",
//           transform: "translateY(-5px)",
//           boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)"
//         }
//       }}
//     >
//       <CardContent sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Mail sx={{ color: "#666", mr: 1 }} />
//           <Typography variant="body2" sx={{ fontWeight: 600, color: "rgb(0, 0, 0)" }}>
//             {empEmail}
//           </Typography>
//         </Box>
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Person sx={{ color: "#666", mr: 1 }} />
//           <Typography variant="body2" sx={{ fontWeight: 600, color: "rgb(0, 0, 0)" }}>
//             {empUserName}
//           </Typography>
//         </Box>
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <AccessTime sx={{ color: "#666", mr: 1 }} />
//           <Typography variant="body2" sx={{ fontWeight: 600, color: "rgb(0, 0, 0)" }}>
//             {new Date(createdTime).toLocaleString()}
//           </Typography>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

// TaskCard.propTypes = {
//   id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   empEmail: PropTypes.string.isRequired,
//   empUserName: PropTypes.string.isRequired,
//   createdTime: PropTypes.string.isRequired,
//   status: PropTypes.string.isRequired
// };

// // Column component with new UI
// const Column = ({ id, title, cards, onDrop, index }) => {
//   const handleDragOver = (e) => {
//     e.preventDefault();
//     e.dataTransfer.dropEffect = "move";
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     try {
//       const cardData = JSON.parse(e.dataTransfer.getData("application/json"));
//       onDrop(cardData, title, id);
//     } catch (error) {
//       console.error("Error handling drop:", error);
//     }
//   };

//   return (
//     <Paper
//       onDragOver={handleDragOver}
//       onDrop={handleDrop}
//       sx={{
//         width: "100%",
//         backgroundColor: "#F9FAFB",
//         padding: "1.5rem",
//         margin: "0.5rem",
//         borderRadius: "12px",
//         border: "1px solid #E0E0E0",
//         boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//         transition: "box-shadow 0.3s ease",
//         '&:hover': {
//           boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)"
//         }
//       }}
//     >
//       <Typography
//         variant="h6"
//         sx={{
//           color: "#1A73E8",
//           mb: 2,
//           textAlign: "center",
//           fontWeight: "bold"
//         }}
//       >
//         {title}
//       </Typography>
//       <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
//         {cards?.map((card) => (
//           <TaskCard key={card.id} {...card} status={title} />
//         ))}
//       </Box>
//     </Paper>
//   );
// };

// Column.propTypes = {
//   id: PropTypes.number.isRequired,
//   title: PropTypes.string.isRequired,
//   cards: PropTypes.arrayOf(PropTypes.shape({
//     id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//     empEmail: PropTypes.string.isRequired,
//     empUserName: PropTypes.string.isRequired,
//     createdTime: PropTypes.string.isRequired
//   })).isRequired,
//   onDrop: PropTypes.func.isRequired,
//   index: PropTypes.number.isRequired
// };

// // Main board component with handleDrop defined
// const ControlledBoard = () => {
//   const [columns, setColumns] = useState([]);
//   const [lifeCycleStatus, setLifeCycleStatus] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const user = APIData.sessiondetails;

//   // handleDrop function
//   const handleDrop = async (card, newStatus, newColumnId) => {
//     const sourceColumn = columns.find(col => col.title === card.status);
//     const destinationColumn = columns.find(col => col.id === newColumnId);

//     if (!sourceColumn || !destinationColumn) {
//       console.error("Could not find source or destination column");
//       return;
//     }

//     if (destinationColumn.id <= sourceColumn.id) {
//       Swal.fire({
//         title: 'Error',
//         text: 'You can\'t move tasks backwards',
//         icon: 'error',
//         confirmButtonColor: '#1A73E8',
//       });
//       return;
//     }

//     if (destinationColumn.id - sourceColumn.id > 1) {
//       Swal.fire({
//         title: 'Error',
//         text: 'You can\'t skip stages',
//         icon: 'error',
//         confirmButtonColor: '#1A73E8',
//       });
//       return;
//     }

//     try {
//       const roles = JSON.parse(sessionStorage.getItem("roles")) || [];
//       const authUser = roles.find(role => role.role === sourceColumn.title);

//       if (
//         user.email.toLowerCase() === authUser?.email_id.toLowerCase() ||
//         user.userType === "SUPERADMIN"
//       ) {
//         await createNewStatus(card, newStatus);
//       } else {
//         Swal.fire({
//           title: 'Error',
//           text: 'You don\'t have permission to perform this action',
//           icon: 'error',
//           confirmButtonColor: '#1A73E8',
//         });
//       }
//     } catch (error) {
//       console.error("Error in handleDrop:", error);
//       Swal.fire({
//         title: 'Error',
//         text: 'Error processing the move',
//         icon: 'error',
//         confirmButtonColor: '#1A73E8',
//       });
//     }
//   };

//   // Fetch lifecycle statuses and update columns
//   const fetchExitLifeCycleStatuses = async () => {
//     setLoading(true);
//     try {
//       const { data: statusData } = await axios.get(
//         `${APIData.api}employee/life-cycle/type?type=${exitKeyWord}&org=${org}`,
//         { headers: APIData.headers }
//       );

//       setLifeCycleStatus(statusData);

//       const columnsData = await Promise.all(
//         statusData.map(async (status) => {
//           const cards = await fetchExitLifeCycleStatusesPending(status.statusName); // Fetch pending statuses
//           return {
//             id: status.id,
//             title: status.statusName,
//             cards
//           };
//         })
//       );

//       const sortedColumns = columnsData.sort((a, b) => parseInt(a.id) - parseInt(b.id));
//       setColumns(sortedColumns);
//     } catch (error) {
//       console.error("Error fetching lifecycle statuses:", error);
//       Swal.fire({
//         title: 'Error',
//         text: 'Failed to fetch lifecycle statuses',
//         icon: 'error',
//         confirmButtonColor: '#1A73E8',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchExitLifeCycleStatuses();
//   }, []);

//   if (loading) {
//     return (
//       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{
//       display: 'flex',
//       gap: 2,
//       p: 2,
//       overflowX: 'auto',
//       backgroundColor: '#F9FAFB',
//       borderRadius: '12px',
//       border: '1px solid #E0E0E0'
//     }}>
//       {columns.map((column, index) => (
//         <Column
//           key={column.id}
//           {...column}
//           onDrop={handleDrop}  // Passing handleDrop function to Column
//           index={index}
//         />
//       ))}
//     </Box>
//   );
// };

// // Admin Console Container Component with modern and clean UI
// const AdminConsoleForExit = () => {
//   return (
//     <PageLayout>
//       <Box sx={{ p: 3 }}>
//         <SoftBox p={3} mb={1} display="flex" alignItems="center">
//           <Link to="/dashboard" style={{ textDecoration: 'none' }}>
//             <AiIcons.AiOutlineCloseCircle />
//           </Link>
//           <SoftTypography variant="h5" fontWeight="medium" sx={{ flex: 1, textAlign: 'center' }}>
//             Exit Process Console
//           </SoftTypography>
//         </SoftBox>
//         <ControlledBoard />
//       </Box>
//     </PageLayout>
//   );
// };

// export default AdminConsoleForExit;
