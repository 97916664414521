import React, { useState, useEffect } from "react";
import { Card, CardContent, CircularProgress, Dialog, DialogContent } from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  AccessTime,
  Close,
  Image as ImageIcon
} from "@mui/icons-material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import Table from "examples/Tables/Table";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { APIData, org } from "authentication/APIData";
import SoftInput from "components/SoftInput";
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
const ChatMessage = ({ message, isMentor }) => {
  const formatDateTime = (dateTimeArray) => {
    if (!dateTimeArray) return '';
    const [year, month, day, hour, minute] = dateTimeArray;
    const date = new Date(year, month - 1, day, hour, minute);
    return date.toLocaleString();
  };

  return (
    <SoftBox
      display="flex"
      justifyContent={isMentor ? "flex-end" : "flex-start"}
      mb={2}
    >
      <SoftBox
        sx={{
          maxWidth: "70%",
          p: 2,
          borderRadius: 2,
          bgcolor: isMentor ? "info.main" : "grey.200",
          color: isMentor ? "white" : "text.primary",
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            width: 0,
            height: 0,
            border: "8px solid transparent",
            borderTopColor: isMentor ? "info.main" : "grey.200",
            top: 0,
            [isMentor ? "right" : "left"]: -8,
          }
        }}
      >
        <SoftTypography variant="body2">{message.comments}</SoftTypography>
        <SoftTypography variant="caption" sx={{ opacity: 0.8, display: "block", mt: 1 }}>
          {formatDateTime(message.updatedDateTime)}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.shape({
    comments: PropTypes.string.isRequired,
    updatedDateTime: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  isMentor: PropTypes.bool.isRequired,
};

const ImageGallery = ({ images, title }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <SoftBox>
      <SoftTypography variant="h6" mb={2}>{title}</SoftTypography>
      {images.length === 0 ? (
        <SoftTypography variant="body2" color="text.secondary">
          No images available
        </SoftTypography>
      ) : (
        <SoftBox
          display="flex"
          flexWrap="wrap"
          gap={2}
          justifyContent="center"
        >
          {images.map((imageUrl, index) => (
            <SoftBox
              key={index}
              sx={{
                width: 100,
                height: 100,
                cursor: 'pointer',
                border: '1px solid',
                borderColor: 'grey.300',
                borderRadius: 2,
                overflow: 'hidden'
              }}
              onClick={() => setSelectedImage(imageUrl)}
            >
              <img
                src={imageUrl}
                alt={`${title} ${index + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </SoftBox>
          ))}

          {/* Full Image Dialog */}
          <Dialog
            open={!!selectedImage}
            onClose={() => setSelectedImage(null)}
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={selectedImage}
                  alt="Full size"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '70vh',
                    objectFit: 'contain'
                  }}
                />
              </SoftBox>
              <SoftButton
                variant="text"
                color="secondary"
                onClick={() => setSelectedImage(null)}
                sx={{ position: 'absolute', top: 8, right: 8 }}
              >
                <Close />
              </SoftButton>
            </DialogContent>
          </Dialog>
        </SoftBox>
      )}
    </SoftBox>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired
};

const MentorMonitor = () => {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [conversationData, setConversationData] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [userdata, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userData_Local = localStorage.getItem("sessiondetails");
    if (userData_Local) {
      try {
        const parsedUserData = JSON.parse(userData_Local);
        setUserData(parsedUserData);
      } catch (error) {
        console.error("Error parsing session details:", error);
      }
    }
  }, []);

  const formatDateTime = (dateTimeArray) => {
    if (!dateTimeArray) return '';
    const [year, month, day, hour, minute] = dateTimeArray;
    const date = new Date(year, month - 1, day, hour, minute);
    return date.toLocaleString();
  };

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetch(`${APIData.api}employee/employeeNameAndEmails?supervisorEmail=${userdata.email}`, {
          headers: APIData.headers
        });
        const data = await response.json();
        setEmails(data);
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };

    if (userdata?.email) {
      fetchEmails();
    }
  }, [userdata]);

  const emailOptions = emails.map(email => ({
    value: email,
    label: email
  }));

  const fetchConversationData = async () => {
    // Input validation checks
    if (!selectedEmail || !fromDate || !toDate) {
      Swal.fire({
        icon: 'warning',
        title: 'Missing Fields',
        text: 'Please fill all fields before proceeding.',
        confirmButtonText: 'OK',
      });
      return;
    }

    const today = new Date().toISOString().split('T')[0];
    if (fromDate > today || toDate > today) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Date Selection',
        text: 'Future dates are not allowed.',
        confirmButtonText: 'OK',
      });
      return;
    }

    if (fromDate > toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Date Range',
        text: 'From date cannot be after To date.',
        confirmButtonText: 'OK',
      });
      return;
    }

    // Set loading state to true before fetch
    setIsLoading(true);

    try {
      const response = await fetch(
        `${APIData.api}student/updates/mentor-date-range?mentorId=${selectedEmail}&org=${org}&fromDate=${fromDate}&toDate=${toDate}`,
        { headers: APIData.headers }
      );
      const data = await response.json();
      setConversationData(data);
      setSelectedConversation(null);

      // Show no data message if array is empty
      if (data.length === 0) {
        Swal.fire({
          icon: 'info',
          title: 'No Data',
          text: 'No conversation data available for this mentor in the selected date range.',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error("Error fetching conversation data:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch conversation data.',
        confirmButtonText: 'OK',
      });
    } finally {
      // Set loading state to false after fetch completes
      setIsLoading(false);
    }
  };

  const LoadingOverlay = () => (
    <SoftBox
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 1000
      }}
    >
      <CircularProgress color="info" />
    </SoftBox>
  );

  const columns = [
    { name: "Mentor", align: "left" },
    { name: "Student", align: "left" },
    { name: "Raised Date", align: "left" },
    { name: "Action", align: "center" }
  ];

  const rows = conversationData
    .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
    .map((conversation) => ({
      Mentor: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox mr={2}>
            <SoftAvatar
              src={`https://ui-avatars.com/api/?name=${encodeURIComponent(conversation.mentorName)}&background=random`}
              alt={conversation.mentorName}
              size="sm"
              variant="rounded"
            />
          </SoftBox>
          <SoftTypography variant="button" fontWeight="medium">
            {conversation.mentorName}
          </SoftTypography>
        </SoftBox>
      ),
      Student: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox mr={2}>
            <SoftAvatar
              src={`https://ui-avatars.com/api/?name=${encodeURIComponent(conversation.studentName)}&background=random`}
              alt={conversation.studentName}
              size="sm"
              variant="rounded"
            />
          </SoftBox>
          <SoftTypography variant="button" fontWeight="medium">
            {conversation.studentName}
          </SoftTypography>
        </SoftBox>
      ),
      "Raised Date": formatDateTime(conversation.createdDateTime),
      Action: (
        <SoftButton
          variant="text"
          color="info"
          onClick={() => setSelectedConversation(conversation)}
        >
          View
        </SoftButton>
      ),
    }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ position: 'relative' }}>
        {isLoading && <LoadingOverlay />}
        <CardContent>
          <SoftBox mb={3}>
            <SoftTypography variant="h6">Mentor Monitor</SoftTypography>
          </SoftBox>

          <SoftBox display="flex" alignItems="center" gap={2} mb={3}>
            <SoftBox display="flex" alignItems="center" gap={2}>
              <SoftTypography variant="h6">Mentor:</SoftTypography>
              <CustomSelect
                options={emails.map(email => ({
                  value: email.officeEmail,
                  label: `${email.name}`
                }))}
                placeholder="Select Mentor Email"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSelectedEmail(selectedOption.value);
                  }
                }}
                value={
                  emails.length > 0
                    ? {
                      value: selectedEmail,
                      label: emails.find(email => email.officeEmail === selectedEmail)?.label || 'Select Mentor Email'
                    }
                    : null
                }
                style={{ width: "300px" }}
              />
            </SoftBox>

            <SoftBox display="flex" alignItems="center" gap={2}>
              <SoftTypography variant="h6">From:</SoftTypography>
              <SoftInput
                type="date"
                required
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                sx={{ width: "150px" }}
                InputProps={{
                  startAdornment: <AccessTime sx={{ mr: 1, color: 'text.secondary' }} />,
                }}
              />
            </SoftBox>

            <SoftBox display="flex" alignItems="center" gap={2}>
              <SoftTypography variant="h6">To:</SoftTypography>
              <SoftInput
                type="date"
                required
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                sx={{ width: "150px" }}
                InputProps={{
                  startAdornment: <AccessTime sx={{ mr: 1, color: 'text.secondary' }} />,
                }}
              />
            </SoftBox>

            <SoftButton
              onClick={fetchConversationData}
              variant="gradient"
              color="info"
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? 'Fetching...' : 'Get Data'}
            </SoftButton>
          </SoftBox>

          <SoftBox display="flex" gap={3}>
            <SoftBox flex={1}>
              {conversationData.length > 0 ? (
                <SoftBox>
                  <Table columns={columns} rows={rows} />
                  <SoftBox display="flex" justifyContent="flex-end" p={2}>
                    <SoftBox display="flex" gap={1} alignItems="center">
                      <SoftTypography variant="caption" color="secondary">
                        {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, conversationData.length)} of {conversationData.length}
                      </SoftTypography>
                      <SoftButton
                        variant="text"
                        color="info"
                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                        disabled={page === 0}
                        iconOnly
                      >
                        <KeyboardArrowLeft />
                      </SoftButton>
                      <SoftButton
                        variant="text"
                        color="info"
                        onClick={() => setPage(prev => Math.min(Math.ceil(conversationData.length / itemsPerPage) - 1, prev + 1))}
                        disabled={page >= Math.ceil(conversationData.length / itemsPerPage) - 1}
                        iconOnly
                      >
                        <KeyboardArrowRight />
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              ) : (
                <SoftTypography 
                  variant="body2" 
                  color="text.secondary" 
                  textAlign="center"
                  py={3}
                >
                  No conversation data available for this mentor.
                </SoftTypography>
              )}
            </SoftBox>

            {/* Right side: Chat */}
            {selectedConversation && (
              <SoftBox
                flex={1}
                sx={{
                  borderLeft: 1,
                  borderColor: "grey.300",
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <SoftBox p={3} bgcolor="grey.100" borderRadius="borderRadius">
                  {/* Conversation Header */}
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <SoftBox display="flex" alignItems="center" gap={2}>
                      <SoftAvatar
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(selectedConversation.studentName)}&background=random`}
                        alt={selectedConversation.studentName}
                        size="sm"
                      />
                      <SoftBox>
                        <SoftTypography variant="h6">
                          {selectedConversation.studentName}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="text.secondary">
                          Grade: {selectedConversation.grade}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <SoftButton
                      variant="text"
                      color="secondary"
                      onClick={() => setSelectedConversation(null)}
                      iconOnly
                    >
                      <Close />
                    </SoftButton>
                  </SoftBox>

                  {/* Chat Messages */}
                  <SoftBox
                    sx={{
                      height: "300px",
                      overflowY: "auto",
                      p: 2,
                      bgcolor: "white",
                      borderRadius: 1
                    }}
                  >
                    {selectedConversation.queries.map((query, index) => (
                      <ChatMessage
                        key={index}
                        message={query}
                        isMentor={query.updatedBy === selectedConversation.mentorId}
                      />
                    ))}
                  </SoftBox>

                  {/* Images Section */}
                  <SoftBox mt={2}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      <SoftTypography variant="h6">Media</SoftTypography>
                      <SoftButton
                        variant="text"
                        color="info"
                        startIcon={<ImageIcon />}
                      >
                        View All
                      </SoftButton>
                    </SoftBox>

                    <SoftBox display="flex" gap={2}>
                      <SoftBox flex={1}>
                        <ImageGallery
                          images={selectedConversation.studentImages || []}
                          title="Student Images"
                        />
                      </SoftBox>
                      <SoftBox flex={1}>
                        <ImageGallery
                          images={selectedConversation.mentorImages || []}
                          title="Mentor Images"
                        />
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox mt={2}>
                    <SoftTypography variant="caption" color="text.secondary">
                      Created: {formatDateTime(selectedConversation.createdDateTime)}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default MentorMonitor;